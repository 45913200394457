/*------- 6. Product style  --------*/
.product-area {
  position: relative;
  .row {
    &.three-column {
      .col-xl-3 {
        flex: 1 0 33.3333%;

        max-width: 33.3333%;

        transition: 0.5s;

        @media #{$lg-layout} {
          flex: 1 0 33.3333%;

          max-width: 33.3333%;
        }
        @media #{$md-layout} {
          flex: 1 0 50%;

          max-width: 50%;
        }
        @media #{$xs-layout} {
          flex: 1 0 100%;

          max-width: 100%;
        }
      }
    }
    &.five-column {
      .col-xl-3 {
        flex: 1 0 20%;

        max-width: 20%;

        transition: 0.5s;

        @media #{$lg-layout} {
          flex: 1 0 33.3333%;

          max-width: 33.3333%;
        }
        @media #{$md-layout} {
          flex: 1 0 50%;

          max-width: 50%;
        }
        @media #{$xs-layout} {
          flex: 1 0 100%;

          max-width: 100%;
        }
      }
    }
  }

  &--style2 {
    .swiper-slider-navigation-wrapper {
      width: 100%;

      text-align: center;
    }
    .ht-swiper-button-nav {
      font-size: 28px;
      line-height: 1;

      position: absolute;
      top: 15px;
      right: 0;

      display: inline-block;

      width: 35px;
      height: 35px;
      padding: 0;

      transition: all 0.3s ease 0s;
      text-align: center;

      color: #c6c6c6;
      border: 1px solid #d2d2d2;
      border-radius: 5px;
      background-color: #f9f9f9;
      i {
        padding-top: 3px;
      }
      @media #{$xs-layout} {
        margin-top: 15px;
      }
      &:hover {
        color: #fff;
        border: 1px solid $theme-color;
        background-color: $theme-color;
      }
      &.swiper-button-prev {
        right: 40px;
        left: auto;
        @media #{$xs-layout} {
          margin-right: 10px;
        }
      }

      @media #{$xs-layout} {
        position: static;

        text-align: center;
      }
    }
  }
}

.product-tab-list {
  justify-content: center;
  &.pt-30,
  &.pt-55 {
    @media #{$xs-layout} {
      padding: 10px 0 30px;
    }
  }
  @media #{$md-layout} {
    &.pt-35,
    &.pt-60 {
      padding: 20px 0 30px;
    }
  }
  @media #{$xs-layout} {
    &.pt-35,
    &.pt-60 {
      padding: 10px 0 10px;
    }
  }
  a {
    margin: 0 11px;
    &.nav-link {
      background-color: transparent !important;
    }
    @media #{$xs-layout} {
      margin: 0 11px 5px;
    }
    h4 {
      font-size: 18px;
      font-weight: 500;

      margin: 0;

      transition: all 0.3s ease 0s;

      color: #555;
      &:hover {
        color: #000;
      }
    }
    &.active h4 {
      color: #000;
    }
  }
  &.product-tab-fruits {
    a {
      h4 {
        color: #000000;
        border-bottom: 2px solid transparent;
        &:hover {
          color: #6eab49;
        }
      }
      &.active h4 {
        color: #6eab49;
        border-bottom: 2px solid #6eab49;
      }
    }
  }
  &.product-tab-pink {
    a {
      h4 {
        color: #000000;
        border-bottom: 2px solid transparent;
        &:hover {
          color: #e90042;
        }
      }
      &.active h4 {
        color: #e90042;
        border-bottom: 2px solid #e90042;
      }
    }
  }
  &.product-tab-pink2 {
    a {
      h4 {
        color: #000000;
        border-bottom: 2px solid transparent;
        &:hover {
          color: #ed59a0;
        }
      }
      &.active h4 {
        color: #ed59a0;
        border-bottom: 2px solid ed59a0e90042;
      }
    }
  }

  &.product-tab-white {
    a {
      h4 {
        color: #fff;
      }
    }
  }

  &--style2 {
    .nav-item {
      a {
        margin: 0;
        margin-left: 20px;
        padding: 0;

        text-transform: uppercase;
        &.active {
          h4 {
            color: #fed700;
          }
        }
        h4 {
          font-weight: 700;

          color: #5f5f5f;
        }
      }

      &:first-child {
        a {
          margin-left: 0;
        }
      }
    }

    @media #{$md-layout,
    $xs-layout} {
      margin-top: 10px;
    }
  }
}

.product-wrap,
.product-list-image-wrap {
  position: relative;
  .product-img {
    position: relative;

    overflow: hidden;
    img {
      width: 100%;
    }

    &-badges {
      position: absolute;
      top: 12px;
      right: 12px;
      span {
        font-size: 13px;
        font-weight: 500;
        line-height: 1;

        display: block;

        margin-bottom: 10px;
        padding: 3px 11px;

        color: #fff;
        border-radius: 3px;
        &.pink {
          background-color: #fa6bff;
        }
        &.purple {
          background-color: $theme-color;
        }
        &.spedizionetag {
          background-color: $tagspedizione-color;
        }
        &.newtag {
          background-color: $tag_new;
        }
        &.scontotag {
          background-color: $tag_sconto;
        }


        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .hover-img {
      position: absolute;
      top: 0;
      left: 50%;

      visibility: hidden;
      background-color: white;
      transition-duration: 0.7s;
      transform: translate(-50%, 20px);

      opacity: 0;
    }
    .product-action {
      position: absolute;
      z-index: 9;
      bottom: 0;
      left: 50%;

      display: flex;
      align-items: center;
      flex-wrap: nowrap;

      width: 100%;

      transform: translateX(-50%);
      > div {
        display: flex;
        visibility: hidden;
        align-items: center;
        justify-content: center;

        height: 48px;

        transition: all 0.5s ease;
        transform: translateY(20px);

        opacity: 0;
        background-color: $theme-color;
        // &:hover {
        //     background-color: #000;
        // }
      }
      .pro-wishlist {
        width: 48px;

        border-right: 1px solid rgba(255, 255, 255, 0.2);
      }
      .pro-cart {
        font-size: 14px;

        //width: calc(100% - 48px - 48px);
        width: 100%;
        transition-delay: 0.1s;
      }
      .pro-quickview {
        width: 48px;
        margin: 0;

        transition-delay: 0.2s;

        border-left: 1px solid rgba(255, 255, 255, 0.2);
      }
      .pro-same-action {
        a,
        button {
          font-size: 16px;
          line-height: 48px;

          display: block;

          width: 100%;
          height: 48px;

          text-align: center;
          text-transform: capitalize;

          color: #fff;
          border: none;
          background: none;
          &:hover,
          &.active {
            background-color: #000;
          }
        }
        &.pro-wishlist,
        &.pro-quickview {
          a {
            margin-top: 2px;
          }
        }
      }
    }
  }
  .product-content {
    margin: 20px 0 0;
    h3 {
      font-size: 16px;

      margin: 0;
    }
    .product-rating {
      margin: 3px 0 3px;
      i {
        font-size: 17px;

        margin: 0 3px;

        color: #5f5d5d;
        &.yellow {
          color: #ffa900;
        }
      }
    }
    .product-price {
      span {
        font-size: 15px;
        font-weight: 500;

        position: relative;

        margin: 0 9px;

        color: #000;
        &::before {
          position: absolute;
          top: 10px;
          right: -13px;

          width: 7px;
          height: 2px;

          content: "";

          background-color: #000;
        }
        &:last-child:before {
          display: none;
        }
        &.old {
          text-decoration: line-through;

          color: #8e8e8e;
        }
      }
    }
  }
  &:hover .hover-img {
    visibility: visible;

    transform: translate(-50%, 0);

    opacity: 1;
  }
  &:hover .product-action > div {
    visibility: visible;

    transform: translateY(0);

    opacity: 1;
  }
}

.modal-dialog {
  width: 960px;
  max-width: 960px;
  margin: 8% auto;
  padding: 35px;
  @media #{$md-layout} {
    width: 720px;
    max-width: 720px;
  }
  @media #{$xs-layout} {
    width: 100%;
    max-width: 100%;
    padding: 35px 0;
  }
  .modal-header {
    .close {
      cursor: pointer;

      opacity: 1;
      color: #333;
      &:hover {
        color: $theme-color;
      }
    }
  }
  .modal-body {
    padding: 35px 15px;
  }
  .quickview-big-img {
    img {
      width: 100%;
    }
  }
}

.product-details-content {
  @media #{$lg-layout} {
    &.ml-70 {
      margin-left: 0;
    }
  }
  @media #{$md-layout} {
    &.ml-70 {
      margin-left: 0;
    }
  }
  @media #{$xs-layout} {
    &.ml-70 {
      margin-top: 30px;
      margin-left: 0;
    }
  }
  &.quickview-content {
    @media #{$xs-layout} {
      margin-top: 30px;
    }
  }
  h2 {
    font-size: 24px;
    line-height: 1;

    margin: 0 0 0;

    color: #010101;
  }
  .product-details-price {
    display: flex;
    align-items: center;

    margin: 15px 0 26px;
    span {
      font-size: 24px;

      color: #fe5252;
      &.old {
        font-size: 18px;

        margin-left: 20px;

        text-decoration: line-through;

        color: #333;
      }
    }
  }
  .pro-details-rating-wrap {
    display: flex;
    align-items: center;

    margin: 0 0 17px;
    .pro-details-rating {
      position: relative;

      margin-right: 39px;
      &:before {
        position: absolute;
        top: 4px;
        right: -19px;

        display: none;

        width: 2px;
        height: 15px;

        content: "";

        background-color: #d1d1d1;
      }
      i {
        font-size: 17px;

        margin-right: 5px;

        color: #5f5d5d;
        &.yellow {
          color: #ffa900;
        }
      }
    }
    span {
      a {
        font-size: 15px;

        color: $theme-color;
        &:hover {
          color: #000;
        }
      }
    }
  }
  p {
    font-size: 15px;
    line-height: 28px;

    margin: 0;

    color: #333;
  }
  .pro-details-list {
    margin: 20px 0 34px;
    padding: 0 0 37px;

    border-bottom: 1px solid #e5e5e5;
    ul {
      li {
        margin: 0 0 5px;

        color: #333;
        &:last-child {
          margin: 0;
        }
      }
    }
  }
  .pro-details-size-color {
    display: flex;
    @media #{$xs-layout} {
      display: block;
    }
    @media #{$sm-layout} {
      display: flex;
    }
    .pro-details-color-wrap {
      margin-right: 20px;
      @media #{$xs-layout} {
        margin-bottom: 20px;
      }
      @media #{$sm-layout} {
        margin-bottom: 0;
      }
      span {
        font-size: 15px;
        font-weight: 500;

        display: block;

        margin: 0 0 15px;
      }
      .pro-details-color-content {
        ul {
          li {
            position: relative;

            display: block;
            float: left;

            width: 14px;
            height: 14px;
            margin-right: 15px;

            cursor: pointer;
            transition: all 0.4s ease 0s;

            border-radius: 50px;
            &:last-child {
              margin-right: 0;
            }
            &.blu {
              border: 1px solid #0000ff;
              background-color: #0000ff;
            }
            &.morrone {
              border: 1px solid #964B00	;
              background-color: #964B00;
            }
            &.grigio {
              border: 1px solid #808080;
              background-color: #808080;
            }
            &.verde {
              border: 1px solid #00FF00;
              background-color: #00FF00;
            }
            &.giallo {
              border: 1px solid #FFFF00;
              background-color: #FFFF00;
            }
            &.bianco {
              border: 1px solid #333;
              background-color: #fff;
            }
            &.nero {
              border: 1px solid #333;
              background-color: #333;
            }
            &.rosso {
              border: 1px solid #FF0000	;
              background-color: #FF0000	;
            }
            &.viola {
              border: 1px solid #800080;
              background-color: #800080;
            }
            &.celeste {
              border: 1px solid #99CBFF;
              background-color: #99CBFF;
            }
            &.azzurro {
              border: 1px solid #007FFF;
              background-color: #007FFF;
            }
            &.arancione {
              border: 1px solid #FF6600;
              background-color: #FF6600;
            }
            &.rosa {
              border: 1px solid #FFC0CB;
              background-color: #FFC0CB;
            }
            &.lilla {
              border: 1px solid #C8A2C8;
              background-color: #C8A2C8;
            }
            &.beige {
              border: 1px solid #F5F5DC;
              background-color: #F5F5DC;
            }
            &.bordeaux {
              border: 1px solid #800000;
              background-color: #800000;
            }
            &.turchese {
              border: 1px solid #30D5C8;
              background-color: #30D5C8;
            }
            &.Acquamarina{border: 1px solid #7FFFD4 ;background-color: #7FFFD4;}
            &.Albicocca{border: 1px solid #FBCEB1   ;background-color: #FBCEB1;}
            &.Amaranto{border: 1px solid #E52B50    ;background-color: #E52B50;}
            &.Ambra{border: 1px solid #FFBF00       ;background-color: #FFBF00;}
            &.Ametista{border: 1px solid #884DA7    ;background-color: #884DA7;}
            &.Anguria{border: 1px solid #FC6C85     ;background-color: #FC6C85;}
            &.Antracite{border: 1px solid #293133   ;background-color: #293133;}
            &.Aragosta{border: 1px solid #ED7465    ;background-color: #ED7465;}
            &.Arancione{border: 1px solid #FF6600   ;background-color: #FF6600;}
            &.Ardesia{border: 1px solid #708090     ;background-color: #708090;}
            &.Argento{border: 1px solid #C0C0C0     ;background-color: #C0C0C0;}
            &.Asparago{border: 1px solid #87A96B    ;background-color: #87A96B;}
            &.Avio{border: 1px solid #5D8AA8        ;background-color: #5D8AA8;}
            &.Avorio{border: 1px solid #FFFFF0      ;background-color: #FFFFF0;}
            &.Azalea{border: 1px solid #D3305D      ;background-color: #D3305D;}
            &.Azzurro{border: 1px solid #007FFF     ;background-color: #007FFF;}
            &.Azzurrofiordaliso{border: 1px solid #ABCDEF  ;background-color: #ABCDEF;}
            &.Beige{border: 1px solid #F5F5DC       ;background-color: #F5F5DC;}
            &.Beigeolivachiaro{border: 1px solid #908435  ;background-color: #908435;}
            &.Beigeverdastro{border: 1px solid #BEBD7F     ;background-color: #BEBD7F;}
            &.Bianco{border: 1px solid #FFFFFF      ;background-color: #FFFFFF;}
            &.Biancoantico{border: 1px solid #FFFEEF       ;background-color: #FFFEEF;}
            &.Biancoantiflash{border: 1px solid #F2F3F4   ;background-color: #F2F3F4;}
            &.Biancodititanio{border: 1px solid #FAEBD7   ;background-color: #FAEBD7;}
            &.Biancodizinco{border: 1px solid #FEFEE9     ;background-color: #FEFEE9;}
            &.Biancofantasma{border: 1px solid #F8F8FF     ;background-color: #F8F8FF;}
            &.Biancofloreale{border: 1px solid #FFFAF0     ;background-color: #FFFAF0;}
            &.Biancofumo{border: 1px solid #F5F5F5 ;background-color: #F5F5F5;}
            &.BiancoNavajo{border: 1px solid #FFDEAD       ;background-color: #FFDEAD;}
            &.Biscotto{border: 1px solid #FFE4C4    ;background-color: #FFE4C4;}
            &.Bistro{border: 1px solid #3D2B1F      ;background-color: #3D2B1F;}
            &.Blu{border: 1px solid #0000FF ;background-color: #0000FF;}
            &.Bluacciaio{border: 1px solid #4682B4 ;background-color: #4682B4;}
            &.Blualice{border: 1px solid #F0F8FF   ;background-color: #F0F8FF;}
            &.BluBondi{border: 1px solid #0095B6   ;background-color: #0095B6;}
            &.Blucadetto{border: 1px solid #5F9EA0 ;background-color: #5F9EA0;}
            &.Bluceruleo{border: 1px solid #2A52BE ;background-color: #2A52BE;}
            &.Blucomandostellare{border: 1px solid #007BB8        ;background-color: #007BB8;}
            &.BludiPersia{border: 1px solid #1C39BB       ;background-color: #1C39BB;}
            &.BludiPrussia{border: 1px solid #003153      ;background-color: #003153;}
            &.BluDodger{border: 1px solid #1E90FF  ;background-color: #1E90FF;}
            &.Bluelettrico{border: 1px solid #003399       ;background-color: #003399;}
            &.Blumarino{border: 1px solid #000080  ;background-color: #000080;}
            &.Blumedio{border: 1px solid #0000CD   ;background-color: #0000CD;}
            &.Blunotte{border: 1px solid #191970   ;background-color: #191970;}
            &.Bluoltremare{border: 1px solid #120A8F       ;background-color: #120A8F;}
            &.Blupolvere{border: 1px solid #B0E0E6 ;background-color: #B0E0E6;}
            &.Blupolvere scuro{border: 1px solid #003399   ;background-color: #003399;}
            &.Blureale{border: 1px solid #4169E1   ;background-color: #4169E1;}
            &.Bluscuro{border: 1px solid #00008B   ;background-color: #00008B;}
            &.Bordeaux{border: 1px solid #800000    ;background-color: #800000;}
            &.Borgogna{border: 1px solid #800020    ;background-color: #800020;}
            &.Bronzo{border: 1px solid #CD7F32      ;background-color: #CD7F32;}
            &.Bronzoantico{border: 1px solid #75663F       ;background-color: #75663F;}
            &.Camoscio{border: 1px solid #F0DC82    ;background-color: #F0DC82;}
            &.Carbone{border: 1px solid #050402     ;background-color: #050402;}
            &.Cardo{border: 1px solid #D8BFD8       ;background-color: #D8BFD8;}
            &.Carminio{border: 1px solid #960018    ;background-color: #960018;}
            &.Cartadazucchero{border: 1px solid #E0FFFF   ;background-color: #E0FFFF;}
            &.Castagno{border: 1px solid #CD5C5C    ;background-color: #CD5C5C;}
            &.Castagnoscuro{border: 1px solid #986960      ;background-color: #986960;}
            &.Castagnochiaro{border: 1px solid #DDADAF     ;background-color: #DDADAF;}
            &.Catrame{border: 1px solid #D2B48C     ;background-color: #D2B48C;}
            &.Catramescuro{border: 1px solid #918151       ;background-color: #918151;}
            &.Celadon{border: 1px solid #ACE1AF     ;background-color: #ACE1AF;}
            &.Celeste{border: 1px solid #99CBFF     ;background-color: #99CBFF;}
            &.Ceruleo{border: 1px solid #007BA7     ;background-color: #007BA7;}
            &.Ceruleoscuro{border: 1px solid #08457E       ;background-color: #08457E;}
            &.Chartreuse{border: 1px solid #7FFF00  ;background-color: #7FFF00;}
            &.Ciano{border: 1px solid #00FFFF       ;background-color: #00FFFF;}
            &.Ciliegia{border: 1px solid #DE3163    ;background-color: #DE3163;}
            &.Cioccolato{border: 1px solid #D2691E  ;background-color: #D2691E;}
            &.Cobalto{border: 1px solid #0047AB     ;background-color: #0047AB;}
            &.Conchiglia{border: 1px solid #FFF5EE  ;background-color: #FFF5EE;}
            &.Corallo{border: 1px solid #FF7F50     ;background-color: #FF7F50;}
            &.Crema{border: 1px solid #FFFDD0       ;background-color: #FFFDD0;}
            &.Cremisi{border: 1px solid #DC143C     ;background-color: #DC143C;}
            &.Denim{border: 1px solid #1560BD       ;background-color: #1560BD;}
            &.Denimchiaro{border: 1px solid #5E86C1        ;background-color: #5E86C1;}
            &.Eliotropo{border: 1px solid #DF73FF   ;background-color: #DF73FF;}
            &.Acru{border: 1px solid #C2B280       ;background-color: #C2B280;}
            &.Fioredigranturco{border: 1px solid #6495ED  ;background-color: #6495ED;}
            &.Fogliadite{border: 1px solid #008080       ;background-color: #008080;}
            &.Fucsia{border: 1px solid #F400A1      ;background-color: #F400A1;}
            &.Fulvo{border: 1px solid #EBB55F       ;background-color: #EBB55F;}
            &.Gainsboro{border: 1px solid #DCDCDC   ;background-color: #DCDCDC;}
            &.Giada{border: 1px solid #00A86B       ;background-color: #00A86B;}
            &.Giallo{border: 1px solid #FFFF00      ;background-color: #FFFF00;}
            &.Giallomiele{border: 1px solid #A98307        ;background-color: #A98307;}
            &.GialloNapoli{border: 1px solid #F7E89F       ;background-color: #F7E89F;}
            &.Giallopastello{border: 1px solid #FFFF66     ;background-color: #FFFF66;}
            &.Giallosabbia{border: 1px solid #C6A664       ;background-color: #C6A664;}
            &.Giallosegnale{border: 1px solid #E5BE01      ;background-color: #E5BE01;}
            &.Gialloscuolabus{border: 1px solid #FFD800    ;background-color: #FFD800;}
            &.Glicine{border: 1px solid #C9A0DC     ;background-color: #C9A0DC;}
            &.Granata{border: 1px solid #7B1B02     ;background-color: #7B1B02;}
            &.Grano{border: 1px solid #F5DEB3       ;background-color: #F5DEB3;}
            &.Grigio5{border: 1px solid #F7F7F7   ;background-color: #F7F7F7;}
            &.GrigioAlluminio{border: 1px solid #8C9194   ;background-color: #8C9194;}
            &.Grigioasparago{border: 1px solid #465945     ;background-color: #465945;}
            &.Grigioardesiascuro{border: 1px solid #2F4F4F        ;background-color: #2F4F4F;}
            &.Grigioardesiachiaro{border: 1px solid #778899       ;background-color: #778899;}
            &.Grigiocenere{border: 1px solid #E4E5E0       ;background-color: #E4E5E0;}
            &.Grigiotopo{border: 1px solid #646B63 ;background-color: #646B63;}
            &.Incarnatoprugna{border: 1px solid #CC8899    ;background-color: #CC8899;}
            &.Indaco{border: 1px solid #4B0082      ;background-color: #4B0082;}
            &.Indacoelettrico{border: 1px solid #6F00FF    ;background-color: #6F00FF;}
            &.Indacoscuro{border: 1px solid #310062        ;background-color: #310062;}
            &.InternationalKleinBlue{border: 1px solid #002FA7    ;background-color: #002FA7;}
            &.Isabella{border: 1px solid #F4F0EC    ;background-color: #F4F0EC;}
            &.Kaki{border: 1px solid #C3B091        ;background-color: #C3B091;}
            &.Kakichiaro{border: 1px solid #F0E68C ;background-color: #F0E68C;}
            &.Kakiscuro{border: 1px solid #BDB76B  ;background-color: #BDB76B;}
            &.Lampone{border: 1px solid #E30B5C     ;background-color: #E30B5C;}
            &.Lavanda{border: 1px solid #E6E6FA     ;background-color: #E6E6FA;}
            &.Lavandapallido{border: 1px solid #DABAD0     ;background-color: #DABAD0;}
            &.Lavandarosata{border: 1px solid #FFF0F5      ;background-color: #FFF0F5;}
            &.Limone{border: 1px solid #FDE910      ;background-color: #FDE910;}
            &.Limonecrema{border: 1px solid #FFFACD        ;background-color: #FFFACD;}
            &.Lilla{border: 1px solid #C8A2C8       ;background-color: #C8A2C8;}
            &.Lime{border: 1px solid #CCFF00        ;background-color: #CCFF00;}
            &.Lino{border: 1px solid #FAF0E6        ;background-color: #FAF0E6;}
            &.Magenta{border: 1px solid #FF00FF     ;background-color: #FF00FF;}
            &.Magentachiaro{border: 1px solid #F984E5      ;background-color: #F984E5;}
            &.Magnolia{border: 1px solid #F8F4FF    ;background-color: #F8F4FF;}
            &.Malva{border: 1px solid #E0B0FF       ;background-color: #E0B0FF;}
            &.Malvachiaro{border: 1px solid #996666        ;background-color: #996666;}
            &.Mandarino{border: 1px solid #FFCC00   ;background-color: #FFCC00;}
            &.Marrone{border: 1px solid #964B00     ;background-color: #964B00;}
            &.Marronechiaro{border: 1px solid #CD853F      ;background-color: #CD853F;}
            &.Marronepastello{border: 1px solid #987654    ;background-color: #987654;}
            &.Marronerosso{border: 1px solid #993300       ;background-color: #993300;}
            &.Marronesabbiachiaro{border: 1px solid #DABDAB       ;background-color: #DABDAB;}
            &.Marronescuro{border: 1px solid #654321       ;background-color: #654321;}
            &.Melanzana{border: 1px solid #990066   ;background-color: #990066;}
            &.Mogano{border: 1px solid #C04000      ;background-color: #C04000;}
            &.Nero{border: 1px solid #000000        ;background-color: #000000;}
            &.Ocra{border: 1px solid #CC7722        ;background-color: #CC7722;}
            &.Olivina{border: 1px solid #9AB973     ;background-color: #9AB973;}
            &.Orchidea{border: 1px solid #DA70D6    ;background-color: #DA70D6;}
            &.Oro{border: 1px solid #FFD700 ;background-color: #FFD700;}
            &.OroRosa{border: 1px solid #b76e79 ;background-color: #b76e79;}
            &.Orovecchio{border: 1px solid #CFB53B ;background-color: #CFB53B;}
            &.Ottoneantico{border: 1px solid #CC9966       ;background-color: #CC9966;}
            &.Ottanio{border: 1px solid #00665C     ;background-color: #00665C;}
            &.Papaia{border: 1px solid #FFEFD5      ;background-color: #FFEFD5;}
            &.Pera{border: 1px solid #D1E231        ;background-color: #D1E231;}
            &.Pervinca{border: 1px solid #CCCCFF    ;background-color: #CCCCFF;}
            &.Pesca{border: 1px solid #FFE5B4       ;background-color: #FFE5B4;}
            &.Pesca scuro{border: 1px solid #FFDAB9 ;background-color: #FFDAB9;}
            &.Pescaarancio{border: 1px solid #FFCC99       ;background-color: #FFCC99;}
            &.Pescagiallo{border: 1px solid #FADFAD        ;background-color: #FADFAD;}
            &.Pistacchio{border: 1px solid #93C572  ;background-color: #93C572;}
            &.Platino{border: 1px solid #E5E4E2     ;background-color: #E5E4E2;}
            &.Porpora{border: 1px solid #B20000     ;background-color: #B20000;}
            &.Prugna{border: 1px solid #660066      ;background-color: #660066;}
            &.Rame{border: 1px solid #B87333        ;background-color: #B87333;}
            &.Rosa{border: 1px solid #FFC0CB        ;background-color: #FFC0CB;}
            &.Rosaarancio{border: 1px solid #FF9966        ;background-color: #FF9966;}
            &.Rosamedio{border: 1px solid #DB244F  ;background-color: #DB244F;}
            &.RosaMountbatten{border: 1px solid #997A8D    ;background-color: #997A8D;}
            &.Rosapallido{border: 1px solid #FADADD        ;background-color: #FADADD;}
            &.Rosapastello{border: 1px solid #FFD1DC       ;background-color: #FFD1DC;}
            &.Rosascuro{border: 1px solid #E75480  ;background-color: #E75480;}
            &.Rosashocking{border: 1px solid #FC0FC0       ;background-color: #FC0FC0;}
            &.Rosavivo{border: 1px solid #FF007F   ;background-color: #FF007F;}
            &.Rosso{border: 1px solid #FF0000       ;background-color: #FF0000;}
            &.Rossoaragosta{border: 1px solid #CC5500      ;background-color: #CC5500;}
            &.Rossocardinale{border: 1px solid #C41E3A     ;background-color: #C41E3A;}
            &.Rossocorsa{border: 1px solid #CC0000 ;background-color: #CC0000;}
            &.RossoFalun{border: 1px solid #801818 ;background-color: #801818;}
            &.Rossofragola{border: 1px solid #CE3018       ;background-color: #CE3018;}
            &.Rossofuoco{border: 1px solid #A61022 ;background-color: #A61022;}
            &.Rossomattone{border: 1px solid #B22222       ;background-color: #B22222;}
            &.Rossomattonechiaro{border: 1px solid #BD8E80        ;background-color: #BD8E80;}
            &.Rossopomodoro{border: 1px solid #FF6347      ;background-color: #FF6347;}
            &.Rossopompeiano{border: 1px solid #D21F1B     ;background-color: #D21F1B;}
            &.Rossorosa{border: 1px solid #FF6088  ;background-color: #FF6088;}
            &.Rossosangue{border: 1px solid #500000        ;background-color: #500000;}
            &.Rossosegnale{border: 1px solid #A52019       ;background-color: #A52019;}
            &.RossoTiziano{border: 1px solid #BA6262       ;background-color: #BA6262;}
            &.Rossoveneziano{border: 1px solid #C80815     ;background-color: #C80815;}
            &.Rossoviolettochiaro{border: 1px solid #DB7093       ;background-color: #DB7093;}
            &.Rubino{border: 1px solid #B72755      ;background-color: #B72755;}
            &.Sabbia{border: 1px solid #F4A460      ;background-color: #F4A460;}
            &.Salmone{border: 1px solid #FF8C69     ;background-color: #FF8C69;}
            &.Salmonescuro{border: 1px solid #E9967A       ;background-color: #E9967A;}
            &.Sangria{border: 1px solid #92000A     ;background-color: #92000A;}
            &.Scarlatto{border: 1px solid #FF2400   ;background-color: #FF2400;}
            &.Senape{border: 1px solid #FFDB58      ;background-color: #FFDB58;}
            &.Seppia{border: 1px solid #704214      ;background-color: #704214;}
            &.Solidago{border: 1px solid #DAA520    ;background-color: #DAA520;}
            &.Solidagoscuro{border: 1px solid #B8860B      ;background-color: #B8860B;}
            &.Tanno{border: 1px solid #D2B48C       ;background-color: #D2B48C;}
            &.Tenne{border: 1px solid #CD5700      ;background-color: #CD5700;}
            &.Terradombra{border: 1px solid #635147       ;background-color: #635147;}
            &.Terradombrabruciata{border: 1px solid #8A3324      ;background-color: #8A3324;}
            &.TerradiSiena{border: 1px solid #E97451      ;background-color: #E97451;}
            &.TerradiSienabruciata{border: 1px solid #531B00     ;background-color: #531B00;}
            &.Testadimoro{border: 1px solid #754909       ;background-color: #754909;}
            &.teverde{border: 1px solid #D0F0C0   ;background-color: #D0F0C0;}
            &.Tronco{border: 1px solid #79443B      ;background-color: #79443B;}
            &.Turchese{border: 1px solid #30D5C8    ;background-color: #30D5C8;}
            &.Turchesechiaro{border: 1px solid #08E8DE     ;background-color: #08E8DE;}
            &.Turchesepallido{border: 1px solid #99FFCC    ;background-color: #99FFCC;}
            &.Turchesescuro{border: 1px solid #116062      ;background-color: #116062;}
            &.Uovodipettirosso{border: 1px solid #00CCCC  ;background-color: #00CCCC;}
            &.Uovodipettirossochiaro{border: 1px solid #96DED1   ;background-color: #96DED1;}
            &.Verde{border: 1px solid #00FF00       ;background-color: #00FF00;}
            &.VerdeCaraibi{border: 1px solid #00CC99       ;background-color: #00CC99;}
            &.Verdeforesta{border: 1px solid #228B22       ;background-color: #228B22;}
            &.Verdechiaro{border: 1px solid #66FF00        ;background-color: #66FF00;}
            &.Verdegiallo{border: 1px solid #ADFF2F        ;background-color: #ADFF2F;}
            &.Verdemarino{border: 1px solid #2E8B57        ;background-color: #2E8B57;}
            &.Verdemarinomedio{border: 1px solid #3CB371  ;background-color: #3CB371;}
            &.Verdemarinoscuro{border: 1px solid #8FBC8F  ;background-color: #8FBC8F;}
            &.Verdementa{border: 1px solid #98FF98 ;background-color: #98FF98;}
            &.Verdementachiaro{border: 1px solid #A6FBB2  ;background-color: #A6FBB2;}
            &.Verdemuschio{border: 1px solid #ADDFAD       ;background-color: #ADDFAD;}
            &.Verdeoliva{border: 1px solid #808000 ;background-color: #808000;}
            &.Verdeolivastro{border: 1px solid #6B8E23     ;background-color: #6B8E23;}
            &.Verdeolivascuro{border: 1px solid #556B2F   ;background-color: #556B2F;}
            &.Verdepastello{border: 1px solid #77DD77      ;background-color: #77DD77;}
            &.Verdepino{border: 1px solid #01796F  ;background-color: #01796F;}
            &.Verdeprimavera{border: 1px solid #00FF7F     ;background-color: #00FF7F;}
            &.Verdeprimaverascuro{border: 1px solid #177245       ;background-color: #177245;}
            &.Verdeufficio{border: 1px solid #008000       ;background-color: #008000;}
            &.Verdesmeraldo{border: 1px solid #50C878      ;background-color: #50C878;}
            &.VerdeVeronese{border: 1px solid #40826D      ;background-color: #40826D;}
            &.Vermiglio{border: 1px solid #FF4D00   ;background-color: #FF4D00;}
            &.Viola{border: 1px solid #800080       ;background-color: #800080;}
            &.Violachiaro{border: 1px solid #9F00FF        ;background-color: #9F00FF;}
            &.Violamelanzana{border: 1px solid #991199     ;background-color: #991199;}
            &.Violetto{border: 1px solid #8000FF    ;background-color: #8000FF;}
            &.Vinaccia{border: 1px solid #C0007F    ;background-color: #C0007F;}
            &.Zafferano{border: 1px solid #F4C430   ;background-color: #F4C430;}
            &.Zafferanoprofondo{border: 1px solid #FF9933  ;background-color: #FF9933;}
            &.Zaffiro{border: 1px solid #0F52BA     ;background-color: #0F52BA;}
          }
        }
        &--single {
          position: relative;

          display: inline-block;

          width: 14px;
          height: 14px;
          margin-right: 15px;

          cursor: pointer;
          transition: all 0.4s ease 0s;

          border-radius: 50px;
          &:last-child {
            margin-right: 0;
          }
          input {
            position: absolute;

            height: 100%;

            cursor: pointer;

            opacity: 0;

            &:checked {
              & ~ .checkmark {
                &:after {
                  visibility: visible;

                  opacity: 1;
                }
              }
            }
          }
          &.blu {
            border: 1px solid #0000ff;
            background-color: #0000ff;
          }
          &.morrone {
            border: 1px solid #964B00	;
            background-color: #964B00;
          }
          &.grigio {
            border: 1px solid #808080;
            background-color: #808080;
          }
          &.verde {
            border: 1px solid #00FF00;
            background-color: #00FF00;
          }
          &.giallo {
            border: 1px solid #FFFF00;
            background-color: #FFFF00;
          }
          &.bianco {
            border: 1px solid #333;
            background-color: #fff;
          }
          &.nero {
            border: 1px solid #333;
            background-color: #333;
          }
          &.rosso {
            border: 1px solid #FF0000	;
            background-color: #FF0000	;
          }
          &.viola {
            border: 1px solid #800080;
            background-color: #800080;
          }
          &.celeste {
            border: 1px solid #99CBFF;
            background-color: #99CBFF;
          }
          &.azzurro {
            border: 1px solid #007FFF;
            background-color: #007FFF;
          }
          &.arancione {
            border: 1px solid #FF6600;
            background-color: #FF6600;
          }
          &.rosa {
            border: 1px solid #FFC0CB;
            background-color: #FFC0CB;
          }
          &.lilla {
            border: 1px solid #C8A2C8;
            background-color: #C8A2C8;
          }
          &.beige {
            border: 1px solid #F5F5DC;
            background-color: #F5F5DC;
          }
          &.bordeaux {
            border: 1px solid #800000;
            background-color: #800000;
          }
          &.turchese {
            border: 1px solid #30D5C8;
            background-color: #30D5C8;
          }
          &.Acquamarina{border: 1px solid #7FFFD4 ;background-color: #7FFFD4;}
          &.Albicocca{border: 1px solid #FBCEB1   ;background-color: #FBCEB1;}
          &.Amaranto{border: 1px solid #E52B50    ;background-color: #E52B50;}
          &.Ambra{border: 1px solid #FFBF00       ;background-color: #FFBF00;}
          &.Ametista{border: 1px solid #884DA7    ;background-color: #884DA7;}
          &.Anguria{border: 1px solid #FC6C85     ;background-color: #FC6C85;}
          &.Antracite{border: 1px solid #293133   ;background-color: #293133;}
          &.Aragosta{border: 1px solid #ED7465    ;background-color: #ED7465;}
          &.Arancione{border: 1px solid #FF6600   ;background-color: #FF6600;}
          &.Ardesia{border: 1px solid #708090     ;background-color: #708090;}
          &.Argento{border: 1px solid #C0C0C0     ;background-color: #C0C0C0;}
          &.Asparago{border: 1px solid #87A96B    ;background-color: #87A96B;}
          &.Avio{border: 1px solid #5D8AA8        ;background-color: #5D8AA8;}
          &.Avorio{border: 1px solid #FFFFF0      ;background-color: #FFFFF0;}
          &.Azalea{border: 1px solid #D3305D      ;background-color: #D3305D;}
          &.Azzurro{border: 1px solid #007FFF     ;background-color: #007FFF;}
          &.Azzurrofiordaliso{border: 1px solid #ABCDEF  ;background-color: #ABCDEF;}
          &.Beige{border: 1px solid #F5F5DC       ;background-color: #F5F5DC;}
          &.Beigeolivachiaro{border: 1px solid #908435  ;background-color: #908435;}
          &.Beigeverdastro{border: 1px solid #BEBD7F     ;background-color: #BEBD7F;}
          &.Bianco{border: 1px solid #FFFFFF      ;background-color: #FFFFFF;}
          &.Biancoantico{border: 1px solid #FFFEEF       ;background-color: #FFFEEF;}
          &.Biancoantiflash{border: 1px solid #F2F3F4   ;background-color: #F2F3F4;}
          &.Biancodititanio{border: 1px solid #FAEBD7   ;background-color: #FAEBD7;}
          &.Biancodizinco{border: 1px solid #FEFEE9     ;background-color: #FEFEE9;}
          &.Biancofantasma{border: 1px solid #F8F8FF     ;background-color: #F8F8FF;}
          &.Biancofloreale{border: 1px solid #FFFAF0     ;background-color: #FFFAF0;}
          &.Biancofumo{border: 1px solid #F5F5F5 ;background-color: #F5F5F5;}
          &.BiancoNavajo{border: 1px solid #FFDEAD       ;background-color: #FFDEAD;}
          &.Biscotto{border: 1px solid #FFE4C4    ;background-color: #FFE4C4;}
          &.Bistro{border: 1px solid #3D2B1F      ;background-color: #3D2B1F;}
          &.Blu{border: 1px solid #0000FF ;background-color: #0000FF;}
          &.Bluacciaio{border: 1px solid #4682B4 ;background-color: #4682B4;}
          &.Blualice{border: 1px solid #F0F8FF   ;background-color: #F0F8FF;}
          &.BluBondi{border: 1px solid #0095B6   ;background-color: #0095B6;}
          &.Blucadetto{border: 1px solid #5F9EA0 ;background-color: #5F9EA0;}
          &.Bluceruleo{border: 1px solid #2A52BE ;background-color: #2A52BE;}
          &.Blucomandostellare{border: 1px solid #007BB8        ;background-color: #007BB8;}
          &.BludiPersia{border: 1px solid #1C39BB       ;background-color: #1C39BB;}
          &.BludiPrussia{border: 1px solid #003153      ;background-color: #003153;}
          &.BluDodger{border: 1px solid #1E90FF  ;background-color: #1E90FF;}
          &.Bluelettrico{border: 1px solid #003399       ;background-color: #003399;}
          &.Blumarino{border: 1px solid #000080  ;background-color: #000080;}
          &.Blumedio{border: 1px solid #0000CD   ;background-color: #0000CD;}
          &.Blunotte{border: 1px solid #191970   ;background-color: #191970;}
          &.Bluoltremare{border: 1px solid #120A8F       ;background-color: #120A8F;}
          &.Blupolvere{border: 1px solid #B0E0E6 ;background-color: #B0E0E6;}
          &.Blupolvere scuro{border: 1px solid #003399   ;background-color: #003399;}
          &.Blureale{border: 1px solid #4169E1   ;background-color: #4169E1;}
          &.Bluscuro{border: 1px solid #00008B   ;background-color: #00008B;}
          &.Bordeaux{border: 1px solid #800000    ;background-color: #800000;}
          &.Borgogna{border: 1px solid #800020    ;background-color: #800020;}
          &.Bronzo{border: 1px solid #CD7F32      ;background-color: #CD7F32;}
          &.Bronzoantico{border: 1px solid #75663F       ;background-color: #75663F;}
          &.Camoscio{border: 1px solid #F0DC82    ;background-color: #F0DC82;}
          &.Carbone{border: 1px solid #050402     ;background-color: #050402;}
          &.Cardo{border: 1px solid #D8BFD8       ;background-color: #D8BFD8;}
          &.Carminio{border: 1px solid #960018    ;background-color: #960018;}
          &.Cartadazucchero{border: 1px solid #E0FFFF   ;background-color: #E0FFFF;}
          &.Castagno{border: 1px solid #CD5C5C    ;background-color: #CD5C5C;}
          &.Castagnoscuro{border: 1px solid #986960      ;background-color: #986960;}
          &.Castagnochiaro{border: 1px solid #DDADAF     ;background-color: #DDADAF;}
          &.Catrame{border: 1px solid #D2B48C     ;background-color: #D2B48C;}
          &.Catramescuro{border: 1px solid #918151       ;background-color: #918151;}
          &.Celadon{border: 1px solid #ACE1AF     ;background-color: #ACE1AF;}
          &.Celeste{border: 1px solid #99CBFF     ;background-color: #99CBFF;}
          &.Ceruleo{border: 1px solid #007BA7     ;background-color: #007BA7;}
          &.Ceruleoscuro{border: 1px solid #08457E       ;background-color: #08457E;}
          &.Chartreuse{border: 1px solid #7FFF00  ;background-color: #7FFF00;}
          &.Ciano{border: 1px solid #00FFFF       ;background-color: #00FFFF;}
          &.Ciliegia{border: 1px solid #DE3163    ;background-color: #DE3163;}
          &.Cioccolato{border: 1px solid #D2691E  ;background-color: #D2691E;}
          &.Cobalto{border: 1px solid #0047AB     ;background-color: #0047AB;}
          &.Conchiglia{border: 1px solid #FFF5EE  ;background-color: #FFF5EE;}
          &.Corallo{border: 1px solid #FF7F50     ;background-color: #FF7F50;}
          &.Crema{border: 1px solid #FFFDD0       ;background-color: #FFFDD0;}
          &.Cremisi{border: 1px solid #DC143C     ;background-color: #DC143C;}
          &.Denim{border: 1px solid #1560BD       ;background-color: #1560BD;}
          &.Denimchiaro{border: 1px solid #5E86C1        ;background-color: #5E86C1;}
          &.Eliotropo{border: 1px solid #DF73FF   ;background-color: #DF73FF;}
          &.Acru{border: 1px solid #C2B280       ;background-color: #C2B280;}
          &.Fioredigranturco{border: 1px solid #6495ED  ;background-color: #6495ED;}
          &.Fogliadite{border: 1px solid #008080       ;background-color: #008080;}
          &.Fucsia{border: 1px solid #F400A1      ;background-color: #F400A1;}
          &.Fulvo{border: 1px solid #EBB55F       ;background-color: #EBB55F;}
          &.Gainsboro{border: 1px solid #DCDCDC   ;background-color: #DCDCDC;}
          &.Giada{border: 1px solid #00A86B       ;background-color: #00A86B;}
          &.Giallo{border: 1px solid #FFFF00      ;background-color: #FFFF00;}
          &.Giallomiele{border: 1px solid #A98307        ;background-color: #A98307;}
          &.GialloNapoli{border: 1px solid #F7E89F       ;background-color: #F7E89F;}
          &.Giallopastello{border: 1px solid #FFFF66     ;background-color: #FFFF66;}
          &.Giallosabbia{border: 1px solid #C6A664       ;background-color: #C6A664;}
          &.Giallosegnale{border: 1px solid #E5BE01      ;background-color: #E5BE01;}
          &.Gialloscuolabus{border: 1px solid #FFD800    ;background-color: #FFD800;}
          &.Glicine{border: 1px solid #C9A0DC     ;background-color: #C9A0DC;}
          &.Granata{border: 1px solid #7B1B02     ;background-color: #7B1B02;}
          &.Grano{border: 1px solid #F5DEB3       ;background-color: #F5DEB3;}
          &.Grigio5{border: 1px solid #F7F7F7   ;background-color: #F7F7F7;}
          &.Grigioasparago{border: 1px solid #465945     ;background-color: #465945;}
          &.Grigioardesiascuro{border: 1px solid #2F4F4F        ;background-color: #2F4F4F;}
          &.Grigioardesiachiaro{border: 1px solid #778899       ;background-color: #778899;}
          &.Grigiocenere{border: 1px solid #E4E5E0       ;background-color: #E4E5E0;}
          &.Grigiotopo{border: 1px solid #646B63 ;background-color: #646B63;}
          &.Incarnatoprugna{border: 1px solid #CC8899    ;background-color: #CC8899;}
          &.Indaco{border: 1px solid #4B0082      ;background-color: #4B0082;}
          &.Indacoelettrico{border: 1px solid #6F00FF    ;background-color: #6F00FF;}
          &.Indacoscuro{border: 1px solid #310062        ;background-color: #310062;}
          &.InternationalKleinBlue{border: 1px solid #002FA7    ;background-color: #002FA7;}
          &.Isabella{border: 1px solid #F4F0EC    ;background-color: #F4F0EC;}
          &.Kaki{border: 1px solid #C3B091        ;background-color: #C3B091;}
          &.Kakichiaro{border: 1px solid #F0E68C ;background-color: #F0E68C;}
          &.Kakiscuro{border: 1px solid #BDB76B  ;background-color: #BDB76B;}
          &.Lampone{border: 1px solid #E30B5C     ;background-color: #E30B5C;}
          &.Lavanda{border: 1px solid #E6E6FA     ;background-color: #E6E6FA;}
          &.Lavandapallido{border: 1px solid #DABAD0     ;background-color: #DABAD0;}
          &.Lavandarosata{border: 1px solid #FFF0F5      ;background-color: #FFF0F5;}
          &.Limone{border: 1px solid #FDE910      ;background-color: #FDE910;}
          &.Limonecrema{border: 1px solid #FFFACD        ;background-color: #FFFACD;}
          &.Lilla{border: 1px solid #C8A2C8       ;background-color: #C8A2C8;}
          &.Lime{border: 1px solid #CCFF00        ;background-color: #CCFF00;}
          &.Lino{border: 1px solid #FAF0E6        ;background-color: #FAF0E6;}
          &.Magenta{border: 1px solid #FF00FF     ;background-color: #FF00FF;}
          &.Magentachiaro{border: 1px solid #F984E5      ;background-color: #F984E5;}
          &.Magnolia{border: 1px solid #F8F4FF    ;background-color: #F8F4FF;}
          &.Malva{border: 1px solid #E0B0FF       ;background-color: #E0B0FF;}
          &.Malvachiaro{border: 1px solid #996666        ;background-color: #996666;}
          &.Mandarino{border: 1px solid #FFCC00   ;background-color: #FFCC00;}
          &.Marrone{border: 1px solid #964B00     ;background-color: #964B00;}
          &.Marronechiaro{border: 1px solid #CD853F      ;background-color: #CD853F;}
          &.Marronepastello{border: 1px solid #987654    ;background-color: #987654;}
          &.Marrone-rosso{border: 1px solid #993300       ;background-color: #993300;}
          &.Marronesabbiachiaro{border: 1px solid #DABDAB       ;background-color: #DABDAB;}
          &.Marronescuro{border: 1px solid #654321       ;background-color: #654321;}
          &.Melanzana{border: 1px solid #990066   ;background-color: #990066;}
          &.Mogano{border: 1px solid #C04000      ;background-color: #C04000;}
          &.Nero{border: 1px solid #000000        ;background-color: #000000;}
          &.Ocra{border: 1px solid #CC7722        ;background-color: #CC7722;}
          &.Olivina{border: 1px solid #9AB973     ;background-color: #9AB973;}
          &.Orchidea{border: 1px solid #DA70D6    ;background-color: #DA70D6;}
          &.Oro{border: 1px solid #FFD700 ;background-color: #FFD700;}
          &.Orovecchio{border: 1px solid #CFB53B ;background-color: #CFB53B;}
          &.Ottoneantico{border: 1px solid #CC9966       ;background-color: #CC9966;}
          &.Ottanio{border: 1px solid #00665C     ;background-color: #00665C;}
          &.Papaia{border: 1px solid #FFEFD5      ;background-color: #FFEFD5;}
          &.Pera{border: 1px solid #D1E231        ;background-color: #D1E231;}
          &.Pervinca{border: 1px solid #CCCCFF    ;background-color: #CCCCFF;}
          &.Pesca{border: 1px solid #FFE5B4       ;background-color: #FFE5B4;}
          &.Pescascuro{border: 1px solid #FFDAB9 ;background-color: #FFDAB9;}
          &.Pesca-arancio{border: 1px solid #FFCC99       ;background-color: #FFCC99;}
          &.Pesca-giallo{border: 1px solid #FADFAD        ;background-color: #FADFAD;}
          &.Pistacchio{border: 1px solid #93C572  ;background-color: #93C572;}
          &.Platino{border: 1px solid #E5E4E2     ;background-color: #E5E4E2;}
          &.Porpora{border: 1px solid #B20000     ;background-color: #B20000;}
          &.Prugna{border: 1px solid #660066      ;background-color: #660066;}
          &.Rame{border: 1px solid #B87333        ;background-color: #B87333;}
          &.Rosa{border: 1px solid #FFC0CB        ;background-color: #FFC0CB;}
          &.Rosaarancio{border: 1px solid #FF9966        ;background-color: #FF9966;}
          &.Rosamedio{border: 1px solid #DB244F  ;background-color: #DB244F;}
          &.RosaMountbatten{border: 1px solid #997A8D    ;background-color: #997A8D;}
          &.Rosapallido{border: 1px solid #FADADD        ;background-color: #FADADD;}
          &.Rosapastello{border: 1px solid #FFD1DC       ;background-color: #FFD1DC;}
          &.Rosascuro{border: 1px solid #E75480  ;background-color: #E75480;}
          &.Rosashocking{border: 1px solid #FC0FC0       ;background-color: #FC0FC0;}
          &.Rosavivo{border: 1px solid #FF007F   ;background-color: #FF007F;}
          &.Rosso{border: 1px solid #FF0000       ;background-color: #FF0000;}
          &.Rossoaragosta{border: 1px solid #CC5500      ;background-color: #CC5500;}
          &.Rossocardinale{border: 1px solid #C41E3A     ;background-color: #C41E3A;}
          &.Rossocorsa{border: 1px solid #CC0000 ;background-color: #CC0000;}
          &.RossoFalun{border: 1px solid #801818 ;background-color: #801818;}
          &.Rossofragola{border: 1px solid #CE3018       ;background-color: #CE3018;}
          &.Rossofuoco{border: 1px solid #A61022 ;background-color: #A61022;}
          &.Rossomattone{border: 1px solid #B22222       ;background-color: #B22222;}
          &.Rossomattonechiaro{border: 1px solid #BD8E80        ;background-color: #BD8E80;}
          &.Rossopomodoro{border: 1px solid #FF6347      ;background-color: #FF6347;}
          &.Rossopompeiano{border: 1px solid #D21F1B     ;background-color: #D21F1B;}
          &.Rossorosa{border: 1px solid #FF6088  ;background-color: #FF6088;}
          &.Rossosangue{border: 1px solid #500000        ;background-color: #500000;}
          &.Rossosegnale{border: 1px solid #A52019       ;background-color: #A52019;}
          &.RossoTiziano{border: 1px solid #BA6262       ;background-color: #BA6262;}
          &.Rossoveneziano{border: 1px solid #C80815     ;background-color: #C80815;}
          &.Rossoviolettochiaro{border: 1px solid #DB7093       ;background-color: #DB7093;}
          &.Rubino{border: 1px solid #B72755      ;background-color: #B72755;}
          &.Sabbia{border: 1px solid #F4A460      ;background-color: #F4A460;}
          &.Salmone{border: 1px solid #FF8C69     ;background-color: #FF8C69;}
          &.Salmonescuro{border: 1px solid #E9967A       ;background-color: #E9967A;}
          &.Sangria{border: 1px solid #92000A     ;background-color: #92000A;}
          &.Scarlatto{border: 1px solid #FF2400   ;background-color: #FF2400;}
          &.Senape{border: 1px solid #FFDB58      ;background-color: #FFDB58;}
          &.Seppia{border: 1px solid #704214      ;background-color: #704214;}
          &.Solidago{border: 1px solid #DAA520    ;background-color: #DAA520;}
          &.Solidagoscuro{border: 1px solid #B8860B      ;background-color: #B8860B;}
          &.Tanno{border: 1px solid #D2B48C       ;background-color: #D2B48C;}
          &.Tenne{border: 1px solid #CD5700      ;background-color: #CD5700;}
          &.Terradombra{border: 1px solid #635147       ;background-color: #635147;}
          &.Terradombrabruciata{border: 1px solid #8A3324      ;background-color: #8A3324;}
          &.TerradiSiena{border: 1px solid #E97451      ;background-color: #E97451;}
          &.TerradiSienabruciata{border: 1px solid #531B00     ;background-color: #531B00;}
          &.Testadimoro{border: 1px solid #754909       ;background-color: #754909;}
          &.teverde{border: 1px solid #D0F0C0   ;background-color: #D0F0C0;}
          &.Tronco{border: 1px solid #79443B      ;background-color: #79443B;}
          &.Turchese{border: 1px solid #30D5C8    ;background-color: #30D5C8;}
          &.Turchesechiaro{border: 1px solid #08E8DE     ;background-color: #08E8DE;}
          &.Turchesepallido{border: 1px solid #99FFCC    ;background-color: #99FFCC;}
          &.Turchesescuro{border: 1px solid #116062      ;background-color: #116062;}
          &.Uovodipettirosso{border: 1px solid #00CCCC  ;background-color: #00CCCC;}
          &.Uovodipettirossochiaro{border: 1px solid #96DED1   ;background-color: #96DED1;}
          &.Verde{border: 1px solid #00FF00       ;background-color: #00FF00;}
          &.VerdeCaraibi{border: 1px solid #00CC99       ;background-color: #00CC99;}
          &.Verdeforesta{border: 1px solid #228B22       ;background-color: #228B22;}
          &.Verdechiaro{border: 1px solid #66FF00        ;background-color: #66FF00;}
          &.Verdegiallo{border: 1px solid #ADFF2F        ;background-color: #ADFF2F;}
          &.Verdemarino{border: 1px solid #2E8B57        ;background-color: #2E8B57;}
          &.Verdemarinomedio{border: 1px solid #3CB371  ;background-color: #3CB371;}
          &.Verdemarinoscuro{border: 1px solid #8FBC8F  ;background-color: #8FBC8F;}
          &.Verdementa{border: 1px solid #98FF98 ;background-color: #98FF98;}
          &.Verdementachiaro{border: 1px solid #A6FBB2  ;background-color: #A6FBB2;}
          &.Verdemuschio{border: 1px solid #ADDFAD       ;background-color: #ADDFAD;}
          &.Verdeoliva{border: 1px solid #808000 ;background-color: #808000;}
          &.Verdeolivastro{border: 1px solid #6B8E23     ;background-color: #6B8E23;}
          &.Verdeolivascuro{border: 1px solid #556B2F   ;background-color: #556B2F;}
          &.Verdepastello{border: 1px solid #77DD77      ;background-color: #77DD77;}
          &.Verdepino{border: 1px solid #01796F  ;background-color: #01796F;}
          &.Verdeprimavera{border: 1px solid #00FF7F     ;background-color: #00FF7F;}
          &.Verdeprimaverascuro{border: 1px solid #177245       ;background-color: #177245;}
          &.Verdeufficio{border: 1px solid #008000       ;background-color: #008000;}
          &.Verdesmeraldo{border: 1px solid #50C878      ;background-color: #50C878;}
          &.VerdeVeronese{border: 1px solid #40826D      ;background-color: #40826D;}
          &.Vermiglio{border: 1px solid #FF4D00   ;background-color: #FF4D00;}
          &.Viola{border: 1px solid #800080       ;background-color: #800080;}
          &.Violachiaro{border: 1px solid #9F00FF        ;background-color: #9F00FF;}
          &.Violamelanzana{border: 1px solid #991199     ;background-color: #991199;}
          &.Violetto{border: 1px solid #8000FF    ;background-color: #8000FF;}
          &.Vinaccia{border: 1px solid #C0007F    ;background-color: #C0007F;}
          &.Zafferano{border: 1px solid #F4C430   ;background-color: #F4C430;}
          &.Zafferanoprofondo{border: 1px solid #FF9933  ;background-color: #FF9933;}
          &.Zaffiro{border: 1px solid #0F52BA     ;background-color: #0F52BA;}
          .checkmark {
            position: relative;

            display: inline-block;

            width: 14px;
            height: 14px;
            margin: 0;
            &:after {
              position: absolute;
              top: -7px;
              left: -4px;

              visibility: hidden;

              width: 20px;
              height: 20px;

              content: "";
              transition: 0.3s;

              opacity: 0;
              border: 2px solid #333;
              border-radius: 50%;
            }
          }

          &:hover {
            input {
              & ~ .checkmark {
                &:after {
                  visibility: visible;

                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
    .pro-details-size {
      span {
        font-size: 15px;
        font-weight: 500;

        display: block;

        margin: 0 0 10px;
      }
      .pro-details-size-content {
        ul {
          li {
            display: inline-block;

            margin: 0 2px 0 0;

            list-style: outside none none;
            a {
              font-size: 12px;
              line-height: 1;

              display: inline-block;

              padding: 6px 9px 7px;

              text-transform: uppercase;

              color: #000;
              background-color: #f1f2f6;
              &:hover {
                color: #fff;
                background-color: $theme-color;
              }
            }
          }
        }
        &--single {
          font-size: 12px;
          line-height: 1;

          position: relative;

          display: inline-block;

          margin: 0 5px 0 0;

          text-transform: uppercase;

          color: #000;
          background-color: #f1f2f6;
          &:hover {
            color: #fff;
            background-color: $theme-color;
          }
          input {
            position: absolute;
            top: 0;
            left: 0;

            height: 100%;

            cursor: pointer;

            opacity: 0;

            &:checked {
              & ~ .size-name {
                color: $white;
                background-color: $theme-color;
              }
            }
          }
          .size-name {
            font-size: 12px;
            font-weight: 400;

            width: 100%;
            margin-bottom: 0;
            padding: 8px;
          }
        }
      }
    }
  }
  .pro-details-quality {
    display: flex;
    align-items: center;

    margin-top: 40px;
    margin-bottom: 34px;
    .cart-plus-minus {
      position: relative;

      display: inline-block;
      overflow: hidden;

      width: 80px;
      height: 60px;
      padding: 0;

      border: 1px solid #e8e8e8;
      .qtybutton {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;

        position: absolute;

        float: inherit;

        width: 24px;
        margin: 0;

        cursor: pointer;
        transition: all 0.3s ease 0s;
        text-align: center;

        color: #8f8f8f;
        border: none;
        background: none;
      }
      .dec.qtybutton {
        top: 0;
        left: 0;

        height: 60px;
      }
      .inc.qtybutton {
        top: 0;
        right: 0;

        height: 60px;
      }
      input.cart-plus-minus-box {
        font-size: 14px;

        float: left;

        width: 80px;
        height: 60px;
        margin: 0;
        padding: 0;

        text-align: center;

        color: #8f8f8f;
        border: medium none;
        background: transparent none repeat scroll 0 0;
      }
    }
    .pro-details-cart {
      margin: 0 25px 0 10px;
      @media #{$xs-layout} {
        margin: 0 10px 0 10px;
      }
      @media #{$md-layout} {
        margin: 0 17px 0 10px;
      }
      a,
      button {
        font-weight: bold;
        line-height: 1;

        z-index: 99;

        display: inline-block;

        padding: 23px 38px 23px;

        text-transform: uppercase;

        color: #fff;
        border: none;
        background: none;
        background-color: $colore_bottone_aggiungi_carrello;
        @media #{$xs-layout} {
          padding: 23px 12px 23px;
        }
        @media #{$md-layout} {
          padding: 22px 22px 22px;
        }
        &:hover {
          border: none;
        }

        &:disabled {
          cursor: not-allowed;
          &:before,
          &:after {
            display: none;
          }
        }
      }
    }
    .pro-details-wishlist {
      a,
      button {
        font-size: 18px;

        color: #000;
        border: none;
        background: none;
        &:hover,
        &.active {
          color: $theme-color;
        }
      }
    }
    .pro-details-compare {
      a,
      button {
        font-size: 18px;

        margin-left: 25px;

        color: #000;
        border: none;
        background: none;
        @media #{$xs-layout} {
          margin-left: 10px;
        }
        &:hover,
        &.active {
          color: $theme-color;
        }
      }
    }
  }
  .pro-details-meta {
    display: flex;

    margin: 0 0 10px;
    span {
      font-size: 15px;

      margin-right: 5px;

      color: #676767;
    }
    ul {
      li {
        display: inline-block;

        margin: 0 10px 0 0;
        a {
          font-size: 15px;

          color: #676767;
          @media #{$xs-layout} {
            font-size: 14px;
          }
          &:hover {
            color: $theme-color;
          }
        }
      }
    }
  }
  .pro-details-social {
    margin: 24px 0 0;
    ul {
      display: flex;
      li {
        margin-right: 40px;
        &:last-child {
          margin-right: 0;
        }
        a {
          font-size: 16px;

          color: #343538;
          &:hover {
            color: $theme-color;
          }
        }
      }
    }
  }
  &.pro-details-slider-content {
    @media #{$xs-layout} {
      &.mt-50 {
        margin-top: 30px;
      }
    }
    .product-details-price {
      justify-content: center;
    }
    p {
      width: 59%;
      margin: 0 auto;
      @media #{$lg-layout} {
        width: 80%;
      }
      @media #{$md-layout} {
        width: 100%;
      }
      @media #{$xs-layout} {
        width: 100%;
      }
    }
    .pro-details-rating-wrap {
      justify-content: center;
    }
    .pro-details-size-color {
      justify-content: center;

      text-align: left;
      @media #{$xs-layout} {
        text-align: center;
        & .pro-details-color-content {
          display: flex;
          justify-content: center;
        }
      }
    }
    .pro-details-quality {
      justify-content: center;
    }
    .pro-details-meta {
      justify-content: center;
    }
    .pro-details-social {
      ul {
        justify-content: center;
      }
    }
  }
}

.quickview-wrap {
  .nav-style-1.owl-carousel > .owl-nav div {
    font-size: 20px;

    left: 0;

    color: #333;
    &:hover {
      color: $theme-color;
    }
    &.owl-next {
      right: 0;
      left: auto;
    }
  }
  &:hover .nav-style-1.owl-carousel > .owl-nav div {
    visibility: visible;

    opacity: 1;
  }
}


/*  prodduct 2     */

.tab-filter-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media #{$xs-layout} {
    display: block;
    &.mb-60 {
      margin-bottom: 30px;
    }
  }
  @media #{$sm-layout} {
    display: flex;
    &.mb-60 {
      margin-bottom: 30px;
    }
  }
}

.product-tab-list-2 {
  @media #{$xs-layout} {
    margin: 0 0 10px;
  }
  @media #{$sm-layout} {
    margin: 0 0 0;
  }

  .nav-item {
    &:first-child {
      .nav-link {
        padding-left: 0;
      }
    }
  }
  a {
    display: inline-block;

    margin-right: 23px;
    &.nav-link {
      background-color: transparent !important;
    }
    @media #{$xs-layout} {
      margin: 0 23px 10px 0;
    }
    @media #{$sm-layout} {
      margin: 0 23px 0 0;
    }
    &:last-child {
      margin-right: 0;
    }
    h4 {
      font-size: 18px;
      font-weight: 500;

      margin: 0;
      padding-bottom: 5px;

      transition: all 0.3s ease 0s;

      color: #555;
      border-bottom: 2px solid transparent;
      &:hover {
        color: #000000;
      }
    }
    &.active {
      h4 {
        color: #000000;
        border-bottom: 2px solid #000000;
      }
    }
  }
}

.filter-active {
  position: relative;
  a,
  button {
    font-size: 18px;
    font-weight: 500;

    color: #010101;
    border: none;
    background: none;
    &:hover,
    &.active {
      color: $theme-color;
    }
    i {
      font-size: 14px;
    }
  }
}
.product-filter-wrapper {
  overflow: hidden;

  height: 0;

  transition: height 0.4s ease-out;

  background-color: #fff;

  .product-filter {
    h5 {
      font-size: 16px;
      font-weight: 500;

      position: relative;

      margin-bottom: 25px;
      padding-bottom: 7px;

      text-transform: capitalize;

      color: #000;
      &::before {
        position: absolute;
        bottom: 0;
        left: 0;

        width: 20px;
        height: 1px;

        content: "";

        background-color: #000;
      }
    }
    ul.sort-by {
      li {
        margin-bottom: 3px;
        &:last-child {
          margin-bottom: 0;
        }
        a {
        }
      }
    }
    ul.color-filter {
      li {
        margin-bottom: 6px;
        &:last-child {
          margin-bottom: 0;
        }
        input {
          display: inline-block;
          float: left;

          width: 20px;
          height: 20px;
          margin: 3px 8px 0 0;
        }
        a {
          text-transform: capitalize;
        }
      }
    }
    .product-tags {
      ul {
        li {
          margin-bottom: 4px;
          a {
            text-transform: capitalize;
          }
        }
      }
    }

    ul {
      li {
        button {
          text-transform: capitalize;

          border: none;
          background: none;
          &:hover,
          &.active {
            color: $theme-color;
          }
        }
      }
    }

    &--tag {
      ul {
        li {
          display: inline-block;
          button {
            margin-right: 10px;
            margin-bottom: 10px;

            text-transform: lowercase;

            border: 1px solid #e6e6e6;
            &:hover {
              border-color: $theme-color;
            }
          }
        }
      }
    }
  }

  &__inner {
    margin-bottom: 60px;
    padding: 45px 45px 7px;

    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
    @media #{$md-layout} {
      padding: 35px 25px 7px;
    }
    @media #{$xs-layout} {
      padding: 35px 25px 7px;
    }
  }
}
.price-filter {
  .price-slider-amount {
    input {
      font-size: 16px;
      font-weight: 500;

      padding: 0;

      border: none;
      background: transparent;
    }
  }
  #slider-range {
    height: 5px;
    margin-bottom: 12px;
    margin-left: auto;

    border: medium none;
    border-radius: 50px;
    background: #dbdbdb none repeat scroll 0 0;
    span {
      transition: all 0.0s ease 0s;
    }
    .ui-slider-range {
      position: absolute;

      display: block;

      transition: all 0.0s ease 0s;

      border: none;
      background: #fa6bff none repeat scroll 0 0;
    }
    .ui-slider-handle {
      width: 15px;
      height: 15px;
      margin-left: 0;

      border: medium none;
      border: 4px solid #fa6bff;
      border-radius: 50%;
      background: #fff none repeat scroll 0 0;
    }
  }
}
.tab-content.jump {
  .tab-pane {
    display: block;
    overflow: hidden;

    height: 0;

    opacity: 0;
    &.active {
      display: block;
      overflow: visible;

      height: auto;

      opacity: 1;
    }
  }
}

.product-wrap-2 {
  position: relative;

  overflow: hidden;
  .product-img {
    position: relative;
    img {
      width: 100%;
    }
    span {
      font-size: 14px;
      font-weight: 500;

      position: absolute;
      top: 20px;
      right: 20px;
      &.pink {
        color: #fa6bff;
      }
      &.red {
        color: #ff3d2a;
      }
      &.purple {
        color: $theme-color;
      }
      &.spedizionetag {
        background-color: $tagspedizione-color;
      }
      &.newtag {
        background-color: $tag_new;
      }
      &.scontotag {
        background-color: $tag_sconto;
      }

    }
    & .default-img {
      transition: all 0.5s ease-in-out;
      background-color: white;
    }
    & .hover-img {
      position: absolute;
      top: 0;
      left: 0;

      visibility: hidden;

      width: 100%;
      background-color: white;
      transition: all 0.5s ease-in-out;
      transform: translateX(100%);

      opacity: 0;
    }
    .product-action-2 {
      position: absolute;
      top: 50%;

      display: flex;
      justify-content: center;

      width: 100%;

      transform: translateY(-50%);
      a,
      button {
        font-size: 16px;
        line-height: 42px;

        display: inline-block;

        width: 42px;
        height: 42px;
        margin: 0 3px;

        transition: all 0.4s ease-in-out;
        transform: scaleX(0);
        text-align: center;

        color: #fff;
        border: none;
        border-radius: 50px;
        background: none;
        background-color: $theme-color;
        &:hover,
        &.active {
          background-color: #fa6bff;
        }
      }
      &.product-action-2-red {
        a {
          background-color: #df262b;
          &:hover {
            background-color: $theme-color;
          }
        }
      }
    }

    &-badges {
      position: absolute;
      top: 12px;
      right: 12px;
      & > span {
        font-size: 13px;
        font-weight: 500;
        line-height: 1;

        position: static;

        display: block;

        margin-bottom: 10px;
        padding: 3px 11px;

        border-radius: 3px;
        &.pink {
          color: #fa6bff;
        }
        &.purple {
          color: $theme-color;
        }
        &.spedizionetag {
          background-color: $tagspedizione-color;
        }
        &.newtag {
          background-color: $tag_new;
        }
        &.scontotag {
          background-color: $tag_sconto;
        }


        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .product-content-2 {
    display: flex;
    justify-content: space-between;

    margin: 20px 0 0;
    .title-price-wrap-2 {
      h3 {
        line-height: 1;

        margin: 0 0 6px;
        a {
          font-size: 16px;

          color: #000000;
          &:hover {
            color: $theme-color;
          }
        }
      }
      .price-2 {
        span {
          font-size: 15px;

          position: relative;

          margin-right: 17px;

          color: #000000;
          &.old {
            text-decoration: line-through;

            color: #fa6bff;
            &.red {
              color: #ff3d2a;
            }
          }
          &::before {
            position: absolute;
            top: 10px;
            right: -13px;

            width: 6px;
            height: 2px;

            content: "";

            background-color: #a5a5a5;
          }
          &:last-child:before {
            display: none;
          }
        }
      }

      &.title-price-wrap-2-white {
        h3 {
          a {
            color: #fff;
            &:hover {
              color: $theme-color;
            }
          }
        }
        .price-2 {
          span {
            color: #fff;
            &.old {
              text-decoration: line-through;

              color: #fa6bff;
            }
          }
        }
      }
    }
    .pro-wishlist-2 {
      a,
      button {
        font-size: 16px;

        display: inline-block;

        margin: 4px 0 0 10px;

        color: #929292;
        border: none;
        background: none;
        &:hover,
        &.active {
          color: $theme-color;
        }
      }
    }
  }
  &:hover .hover-img {
    visibility: visible;

    transform: translateX(0);

    opacity: 1;
  }
  &:hover .default-img {
    visibility: hidden;
    background-color: white;

    transform: translateX(-100%);
  }
  &:hover .product-action-2 a {
    transform: scaleX(1);
  }
  &:hover .product-action-2 button {
    transform: scaleX(1);
  }
  &.pro-glaucous-color {
    .product-img {
      span.glaucous {
        color: #3d6882;
      }
      .product-action-2 {
        a,
        button {
          background-color: #3d6882;
          &:hover,
          &.active {
            color: #000;
            background-color: #fff;
          }
        }
      }
    }
    .product-content-2 {
      .pro-wishlist-2 a:hover {
        color: #3d6882;
      }
      .title-price-wrap-2 {
        h3 a:hover {
          color: #3d6882;
        }
        .price-2 {
          span.old {
            color: #3d6882;
          }
        }
      }
    }
  }

  &.pro-puce-color {
    .product-img {
      span.puce {
        color: #de3e3e;
      }
      .product-action-2 {
        a,
        button {
          background-color: #de3e3e;
          &:hover {
            color: #000;
            background-color: #fff;
          }
        }
      }
    }
    .product-content-2 {
      .pro-wishlist-2 a:hover,
      .pro-wishlist-2 button:hover {
        color: #de3e3e;
      }
      .title-price-wrap-2 {
        h3 a:hover {
          color: #de3e3e;
        }
        .price-2 {
          span.old {
            color: #de3e3e;
          }
        }
      }
    }
  }
}


/* product hm3 */

.section-padding-1 .container-fluid {
  padding: 0 70px;
  @media #{$xl-layout} {
    padding: 0 15px;
  }
  @media #{$lg-layout} {
    padding: 0 30px;
  }
  @media #{$md-layout} {
    padding: 0 40px;
  }
  @media #{$xs-layout} {
    padding: 0 15px;
  }
}


/* product hm4 */

.hm4-section-padding .container-fluid {
  padding: 0 70px;
  @media #{$xx-layout} {
    padding: 0 60px;
  }
  @media #{$xl-layout} {
    padding: 0 30px;
  }
  @media #{$lg-layout} {
    padding: 0 15px;
  }
  @media #{$md-layout} {
    padding: 0 30px;
  }
  @media #{$xs-layout} {
    padding: 0 15px;
  }
}
.toggle-item-active,
.toggle-item-active2,
.toggle-item-active3,
.toggle-item-active4,
.toggle-item-active5,
.toggle-item-active6 {
  display: none;
}

.view-more {
  display: block;

  width: 100%;

  text-align: center;
  a {
    font-weight: 500;
    line-height: 1;

    display: inline-block;

    padding-bottom: 2px;

    text-align: center;

    color: #555;
    border-bottom: 1px solid #cccccc;
    &:hover {
      color: $theme-color;
      border-bottom: 1px solid $theme-color;
    }
  }
  @media #{$xs-layout} {
    &.mt-20 {
      margin-top: 0;
    }
  }

  &.round-btn {
    a {
      font-size: 16px;
      line-height: 1;

      display: inline-block;

      padding: 16px 40px 16px 40px;

      text-transform: capitalize;

      color: #fff;
      border-radius: 34px 34px 34px 34px;
      background-color: #c61a32;

      &:hover {
        background-color: $theme-color;
      }
    }
  }
}


/* product hm5 */

.hm5-section-padding {
  .container-fluid {
    padding-right: 0;
    padding-left: 0;
    @media #{$xs-layout} {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
  .row {
    margin-right: -10px;
    margin-left: -10px;
    @media #{$xs-layout} {
      margin-right: -15px;
      margin-left: -15px;
    }
  }
  div[class^="col-"] {
    padding-right: 10px;
    padding-left: 10px;
    @media #{$xs-layout} {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
}

.product-wrap-3 {
  position: relative;

  .product-img {
    position: relative;
    a {
      img {
        width: 100%;
      }
    }
    &-badges {
      position: absolute;
      z-index: 9;
      top: 12px;
      right: 12px;
      span {
        font-size: 13px;
        font-weight: 500;
        line-height: 1;

        display: block;

        margin-bottom: 10px;
        padding: 3px 11px;

        color: #fff;
        border-radius: 3px;
        &.purple {
          background-color: $theme-color;
        }
        &.spedizionetag {
          background-color: $tagspedizione-color;
  
        }
        &.pink {
          background-color: #fa6bff;
        }
        &.newtag {
          background-color: $tag_new;
        }
        &.scontotag {
          background-color: $tag_sconto;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .product-content-3-wrap {
    position: absolute;
    z-index: 8;
    top: 5%;
    right: 5%;
    bottom: 5%;
    left: 5%;

    visibility: hidden;

    width: 90%;
    height: 90%;

    transition: all 0.4s ease 0s;
    transform: scale(0.85);

    opacity: 0;
    background: rgba(255, 255, 255, 0.9) none repeat scroll 0 0;
    .product-content-3 {
      position: absolute;
      top: 50%;
      left: 50%;

      width: 100%;

      transition: all 0.4s ease 0s;
      transform: translate(-50%, -50%);
      text-align: center;
      .product-title {
        h3 {
          font-size: 18px;
          line-height: 1;

          margin: 0;
        }
      }
      .price-3 {
        margin: 10px 0 20px;
        span {
          font-size: 16px;
          font-weight: 500;

          position: relative;

          margin: 0 9px;

          color: #000;
          &::before {
            position: absolute;
            top: 10px;
            right: -13px;

            width: 7px;
            height: 2px;

            content: "";

            background-color: #000;
          }
          &:last-child::before {
            display: none;
          }
          &.old {
            text-decoration: line-through;

            color: #8e8e8e;
          }
        }
      }
      .product-action-3 {
        .pro-same-action {
          display: inline-block;
        }
        a,
        button {
          font-size: 16px;
          line-height: 42px;

          display: inline-block;

          width: 42px;
          height: 42px;
          margin: 0 3px;

          transition: all 0.4s ease-in-out;
          text-align: center;

          color: #fff;
          border: none;
          border-radius: 50px;
          background: none;
          background-color: #a749ff;
          &:hover,
          &.active {
            background-color: #fa6bff;
          }
          &:disabled {
            cursor: not-allowed;
          }
        }
      }
    }
  }
  &:hover .product-content-3-wrap {
    visibility: visible;

    transform: scale(1);

    opacity: 1;
  }
}




/* product hm6 */

.hm6-section-padding {
  .container-fluid {
    padding-right: 70px;
    padding-left: 70px;
    @media #{$xl-layout} {
      padding-right: 15px;
      padding-left: 15px;
    }
    @media #{$lg-layout} {
      padding-right: 30px;
      padding-left: 30px;
    }
    @media #{$md-layout} {
      padding-right: 40px;
      padding-left: 40px;
    }
    @media #{$xs-layout} {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
  .row {
    margin-right: -10px;
    margin-left: -10px;
    @media #{$xs-layout} {
      margin-right: -15px;
      margin-left: -15px;
    }
  }
  div[class^="col-"] {
    padding-right: 10px;
    padding-left: 10px;
    @media #{$xs-layout} {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
}

.product-wrap-4 {
  position: relative;

  overflow: hidden;
  a {
    img {
      width: 100%;

      transition: all 0.5s ease 0s;
      transform: scale(1);
    }
  }
  .position-1 {
    position: absolute;
    right: 0;
    bottom: 75px;
    left: 0;

    text-align: center;
    @media #{$xx-layout} {
      bottom: 40px;
    }
    @media #{$xl-layout} {
      bottom: 40px;
    }
    @media #{$lg-layout} {
      bottom: 30px;
    }
    @media #{$md-layout} {
      bottom: 30px;
    }
    @media #{$xs-layout} {
      bottom: 30px;
    }
  }
  .position-2 {
    position: absolute;
    bottom: 55px;
    left: 60px;
    @media #{$xx-layout} {
      bottom: 40px;
    }
    @media #{$xl-layout} {
      bottom: 40px;
    }
    @media #{$lg-layout} {
      bottom: 30px;
      left: 40px;
    }
    @media #{$md-layout} {
      bottom: 30px;
      left: 20px;
    }
    @media #{$xs-layout} {
      bottom: 30px;
      left: 20px;
    }
  }
  .position-3 {
    position: absolute;
    top: 51px;
    right: 0;
    left: 0;

    text-align: center;
    @media #{$xx-layout} {
      top: 32px;
    }
    @media #{$xl-layout} {
      top: 32px;
    }
    @media #{$lg-layout} {
      top: 22px;
    }
    @media #{$md-layout} {
      top: 22px;
    }
    @media #{$xs-layout} {
      top: 22px;
    }
  }
  .product-content-4 {
    h4 {
      font-size: 30px;
      line-height: 38px;

      margin: 0 0 12px;
      @media #{$xx-layout} {
        font-size: 25px;
        line-height: 30px;
      }
      @media #{$xl-layout} {
        font-size: 25px;
        line-height: 30px;
      }
      @media #{$lg-layout} {
        font-size: 22px;
        line-height: 27px;
      }
      @media #{$md-layout} {
        font-size: 20px;
        line-height: 23px;
      }
      @media #{$xs-layout} {
        font-size: 20px;
        line-height: 23px;
      }
    }
    .price-4 {
      span {
        font-size: 18px;

        position: relative;

        margin: 0 24px 0 0;

        color: #000000;
        &:before {
          position: absolute;
          top: 12px;
          right: -17px;

          width: 7px;
          height: 2px;

          content: "";

          background-color: #6b6264;
        }
        &:last-child:before {
          display: none;
        }
        &.old {
          text-decoration: line-through;

          color: #fa6bff;
        }
      }
      &.price-4-center {
        span {
          margin: 0 12px;
        }
      }
    }
  }
  &:hover a img {
    transform: scale(1.1);
  }
}

.width-50-percent {
  flex: 0 0 50%;

  max-width: 50%;
}

.width-30-percent {
  flex: 0 0 30%;

  max-width: 30%;
}

/* product home 8*/
.collection-product {
  .collection-img {
    overflow: hidden;
    a {
      img {
        width: 100%;

        transition: all 0.5s ease 0s;
        transform: scale(1);
      }
    }
  }
  .collection-content {
    margin: 23px 0 0;
    span {
      font-weight: 500;
    }
    h4 {
      line-height: 1;

      margin: 4px 0 0;
      a {
        font-size: 16px;
        font-weight: 500;

        color: #000000;
        &:hover {
          color: $theme-color;
        }
      }
    }
    .collection-btn {
      font-size: 13px;
      font-weight: 400;
      line-height: 1;

      position: relative;

      display: inline-block;
      overflow: hidden;

      margin-top: 15px;
      padding: 8px 11px;

      text-transform: uppercase;

      color: #666;
      border: 1px solid #010101;
      border-radius: 50px;
      background-color: transparent;
      &:before,
      &:after {
        position: absolute;
        z-index: -1;
        right: 0;
        bottom: 0;
        left: auto;

        width: 0;
        height: 100%;

        content: "";
        transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
      }

      &:hover {
        color: $white;
        border-color: $theme-color;
        background-color: transparent;
        &:after {
          right: auto;
          left: 0;

          width: 100%;

          background-color: $theme-color;
        }
      }
    }
  }
  &:hover a img {
    transform: scale(1.1);
  }
}

.product-wrap-5 {
  position: relative;
  .product-img {
    position: relative;

    a {
      img {
        width: 100%;
      }
    }
    &-badges {
      position: absolute;
      top: 12px;
      right: 12px;
    }
    span {
      font-size: 13px;
      font-weight: 500;
      line-height: 1;

      display: block;

      margin-bottom: 10px;
      padding: 3px 11px;

      color: #fff;
      border-radius: 3px;
      &.purple {
        background-color: $theme-color;
      }
      &.spedizionetag {
        background-color: $tagspedizione-color;
      }
      &.pink {
        background-color: #fa6bff;
      }
      &.newtag {
        background-color: $tag_new;
      }
      &.scontotag {
        background-color: $tag_sconto;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .product-action-4 {
    position: absolute;
    z-index: 999;
    bottom: 0;
    left: 0;

    display: flex;
    justify-content: center;

    width: 100%;
    padding: 10px;

    transition: all 0.5s ease 0s;
    transform: scaleY(0);
    text-align: center;

    opacity: 1;
    background: none repeat scroll 0 0 #a749ff;
    .pro-same-action {
      margin: 0 2px;
      a,
      button {
        font-size: 16px;
        line-height: 36px;

        display: inline-block;

        width: 35px;
        height: 35px;
        margin: 0 3px;

        text-align: center;

        color: #000;
        border: none;
        border-radius: 50px;
        background: none;
        background-color: #fff;
        &:hover,
        &.active {
          color: #fff;
          background-color: #fa6bff;
        }
      }
    }
  }
  .product-content-5 {
    margin-top: 28px;
    h3 {
      font-size: 16px;
      line-height: 17px;

      margin: 0 0 5px;
      a {
        color: #000;
        &:hover {
          color: $theme-color;
        }
      }
    }
    .price-5 {
      span {
        font-size: 15px;

        position: relative;

        margin: 0 8px;

        color: #000000;
        &:before {
          position: absolute;
          top: 10px;
          right: -13px;

          width: 6px;
          height: 2px;

          content: "";

          background-color: #a5a5a5;
        }
        &:last-child:before {
          display: none;
        }
        &.old {
          text-decoration: line-through;

          color: #fa6bff;
        }
      }
    }
  }
  &:hover .product-action-4 {
    transform: scaleY(1);

    opacity: 1;
  }
}

.product-wrap-6 {
  display: flex;
  .product-img {
    position: relative;

    flex-basis: 170px;

    margin-right: 30px;
    &-badges {
      position: absolute;
      top: 12px;
      right: 12px;
    }
    span {
      font-size: 13px;
      font-weight: 500;
      line-height: 1;

      display: block;

      margin-bottom: 10px;
      padding: 3px 11px;

      color: #fed700;
      border-radius: 3px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }


  .product-content {
    flex-basis: calc(100% - 200px);
    h3 {
      font-size: 16px;
      font-weight: 400;
      line-height: 1;

      margin-bottom: 15px;
      a {
        color: #3d3d3d;
        &:hover {
          color: #fed700;
        }
      }
    }

    .product-price {
      margin-bottom: 10px;
      margin-left: -8px;
      span {
        font-size: 18px;

        position: relative;

        margin: 0 8px;

        color: #000000;
        &:before {
          position: absolute;
          top: 10px;
          right: -13px;

          width: 6px;
          height: 2px;

          content: "";

          background-color: #a5a5a5;
        }
        &:last-child:before {
          display: none;
        }
        &.old {
          text-decoration: line-through;

          color: #fed700;
        }
      }
    }

    .product-rating {
      margin-bottom: 15px;
      i {
        font-size: 17px;

        margin-right: 3px;
        &.yellow {
          color: #ffa900;
        }
      }
    }

    .product-action {
      display: flex;
      justify-content: flex-end;
      .pro-same-action {
        margin-left: 5px;
        &:first-child {
          margin-left: 0;
        }
        button,
        a {
          font-size: 23px;
          line-height: 55px;

          display: inline-block;

          width: 55px;
          height: 55px;

          text-align: center;

          color: #000;
          border: none;
          background: none;
          background-color: #f6f6f6;

          &:hover,
          &.active {
            color: #fff;
            background-color: #fed700;
          }
        }
      }
    }
  }
}

.product-wrap-7 {
  position: relative;

  overflow: hidden;
  .product-img {
    position: relative;
    img {
      width: 100%;
    }
    span {
      font-size: 14px;
      font-weight: 500;

      position: absolute;
      top: 20px;
      right: 20px;

      color: #fed700;
    }
    & .default-img {
      transition: all 0.5s ease-in-out;
      background-color: white;
    }
    & .hover-img {
      position: absolute;
      top: 0;
      left: 0;
      background-color: white;
      visibility: hidden;

      width: 100%;

      transition: all 0.5s ease-in-out;
      transform: translateX(100%);

      opacity: 0;
    }
    .product-action-2 {
      position: absolute;
      right: 10px;
      bottom: 10px;

      display: flex;
      justify-content: flex-end;

      width: 100%;
      a,
      button {
        font-size: 16px;
        line-height: 42px;

        display: inline-block;

        width: 42px;
        height: 42px;
        margin: 0 3px;

        transition: all 0.4s ease-in-out;
        transform: scaleX(0);
        text-align: center;

        color: #fff;
        border: none;
        background: none;
        background-color: #555;
        &:hover,
        &.active {
          background-color: #fed700;
        }
      }
    }

    &-badges {
      position: absolute;
      top: 12px;
      left: 12px;
      & > span {
        font-size: 13px;
        font-weight: 500;
        line-height: 1;

        position: static;

        display: block;

        margin-bottom: 10px;
        padding: 3px 11px;

        border-radius: 3px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .pro-wishlist-2 {
      line-height: 35px;

      position: absolute;
      z-index: 9;
      top: 20px;
      right: 20px;

      text-align: center;

      a,
      button {
        font-size: 16px;

        display: inline-block;

        width: 35px;
        height: 35px;

        transition: all 0.3s ease-in-out;

        color: #fff;
        border: none;
        border-radius: 100%;
        background: #555555;
        &:hover,
        &.active {
          background: #fed700;
        }
      }
    }
  }
  .product-content-2 {
    display: flex;
    justify-content: space-between;

    margin: 20px 0 0;
    .title-price-wrap-2 {
      h3 {
        line-height: 1;

        margin: 0 0 6px;
        a {
          font-size: 16px;
          font-weight: 500;

          color: #444;
          &:hover {
            color: #fed700;
          }
        }
      }
      .price-2 {
        span {
          font-size: 15px;

          position: relative;

          margin-right: 17px;

          color: #444;
          &.old {
            text-decoration: line-through;

            color: #444;
            &.red {
              color: #ff3d2a;
            }
          }
          &::before {
            position: absolute;
            top: 10px;
            right: -13px;

            width: 6px;
            height: 2px;

            content: "";

            background-color: #a5a5a5;
          }
          &:last-child:before {
            display: none;
          }
        }
      }
    }
  }
  &:hover .hover-img {
    visibility: visible;

    transform: translateX(0);

    opacity: 1;
  }

  &:hover .product-action-2 a {
    transform: scaleX(1);
  }
  &:hover .product-action-2 button {
    transform: scaleX(1);
  }
}

.product-wrap-8 {
  display: flex;
  .product-img {
    position: relative;

    flex-basis: 170px;

    margin-right: 30px;
    &-badges {
      position: absolute;
      top: 12px;
      right: 12px;
    }
    span {
      font-size: 13px;
      font-weight: 500;
      line-height: 1;

      display: block;

      margin-bottom: 10px;
      padding: 3px 11px;

      color: #fff;
      border-radius: 3px;
      background-color: #ff0000;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }


  .product-content {
    flex-basis: calc(100% - 200px);
    h3 {
      font-size: 16px;
      font-weight: 400;
      line-height: 1;

      margin-bottom: 15px;
      a {
        color: #3d3d3d;
        &:hover {
          color: #ff0000;
        }
      }
    }

    .product-price {
      margin-bottom: 10px;
      margin-left: -8px;
      span {
        font-size: 18px;

        position: relative;

        margin: 0 8px;

        color: #000000;
        &:before {
          position: absolute;
          top: 10px;
          right: -13px;

          width: 6px;
          height: 2px;

          content: "";

          background-color: #a5a5a5;
        }
        &:last-child:before {
          display: none;
        }
        &.old {
          text-decoration: line-through;

          color: #ff0000;
        }
      }
    }

    .product-rating {
      margin-bottom: 15px;
      i {
        font-size: 17px;

        margin-right: 3px;
        &.yellow {
          color: #ffa900;
        }
      }
    }

    .product-action {
      display: flex;
      justify-content: flex-end;
      .pro-same-action {
        margin-left: 5px;
        &:first-child {
          margin-left: 0;
        }
        button,
        a {
          font-size: 23px;
          line-height: 55px;

          display: inline-block;

          width: 55px;
          height: 55px;

          text-align: center;

          color: #000;
          border: none;
          background: none;
          background-color: #f6f6f6;

          &:hover,
          &.active {
            color: #fff;
            background-color: #ff0000;
          }
        }
      }
    }
  }

  &--brown {
    .product-img {
      span {
        background-color: #bca487;
      }
    }
    .product-content {
      h3 {
        a {
          &:hover {
            color: #bca487;
          }
        }
      }

      .product-price {
        span {
          &.old {
            color: #bca487;
          }
        }
      }

      .product-action {
        .pro-same-action {
          button,
          a {
            &:hover,
            &.active {
              background-color: #bca487;
            }
          }
        }
      }
    }
  }
}

.product-wrap-9 {
  position: relative;

  overflow: hidden;
  .product-img {
    position: relative;
    img {
      width: 100%;
    }
    span {
      font-size: 14px;
      font-weight: 500;

      position: absolute;
      top: 20px;
      right: 20px;

      color: #ff0000;
    }
    & .default-img {
      transition: all 0.5s ease-in-out;
      background-color: white;
    }
    & .hover-img {
      position: absolute;
      top: 0;
      left: 0;

      visibility: hidden;

      width: 100%;

      transition: all 0.5s ease-in-out;
      transform: translateX(100%);

      opacity: 0;
    }
    .product-action-2 {
      position: absolute;
      right: 10px;
      bottom: 0;

      display: flex;
      justify-content: flex-end;

      width: 100%;
      a,
      button {
        font-size: 16px;
        line-height: 55px;

        display: inline-block;

        width: 55px;
        height: 55px;
        margin: 0 3px;

        transition: all 0.4s ease-in-out;
        transform: scaleX(0);
        text-align: center;

        color: #555;
        border: none;
        background: none;
        background-color: #fff;
        &:hover,
        &.active {
          color: #fff;
          background-color: #ff0000;
        }
      }
    }

    &-badges {
      position: absolute;
      top: 12px;
      right: 12px;
      & > span {
        font-size: 13px;
        font-weight: 500;
        line-height: 1;

        position: static;

        display: block;

        margin-bottom: 10px;
        padding: 3px 11px;

        border-radius: 3px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .product-content-2 {
    display: flex;
    justify-content: space-between;

    margin: 20px 0 0;
    .title-price-wrap-2 {
      h3 {
        font-family: $josefin;
        line-height: 1;

        margin: 0 0 6px;
        a {
          font-size: 16px;
          font-weight: 500;

          color: #444;
          &:hover {
            color: #ff0000;
          }
        }
      }
      .price-2 {
        span {
          font-size: 15px;

          position: relative;

          margin-right: 17px;

          color: #444;
          &.old {
            text-decoration: line-through;

            color: #444;
            &.red {
              color: #ff3d2a;
            }
          }
          &::before {
            position: absolute;
            top: 10px;
            right: -13px;

            width: 6px;
            height: 2px;

            content: "";

            background-color: #a5a5a5;
          }
          &:last-child:before {
            display: none;
          }
        }
      }
    }
    .pro-wishlist-2 {
      line-height: 35px;

      z-index: 9;

      text-align: center;

      a,
      button {
        font-size: 16px;

        display: inline-block;

        transition: all 0.3s ease-in-out;

        color: #929292;
        border: none;
        background: none;
        &:hover,
        &.active {
          color: #ff0000;
        }
      }
    }
  }
  &:hover .hover-img {
    visibility: visible;

    transform: translateX(0);

    opacity: 1;
  }

  &:hover .product-action-2 a {
    transform: scaleX(1);
  }
  &:hover .product-action-2 button {
    transform: scaleX(1);
  }
}


.product-wrap-10 {
  position: relative;

  overflow: hidden;
  .product-img {
    position: relative;
    img {
      width: 100%;
    }
    span {
      font-size: 14px;
      font-weight: 500;

      position: absolute;
      top: 20px;
      right: 20px;
    }
    & .default-img {
      transition: all 0.5s ease-in-out;
      background-color: white;
    }
    & .hover-img {
      position: absolute;
      top: 0;
      left: 0;
      background-color: white;
      visibility: hidden;

      width: 100%;

      transition: all 0.5s ease-in-out;
      transform: translateX(100%);

      opacity: 0;
    }
    .product-action-2 {
      position: absolute;
      top: 50%;
      left: 20px;

      display: flex;
      flex-direction: column;
      justify-content: center;

      width: auto;

      transform: translateY(-50%);
      a,
      button {
        font-size: 16px;
        line-height: 42px;

        display: inline-block;

        width: 42px;
        height: 42px;
        margin: 3px 0;

        transition: all 0.4s ease-in-out;
        transform: translateX(-200px);
        text-align: center;

        color: #fff;
        border: none;
        border-radius: 50px;
        background: none;
        background-color: #bca487;
        &:hover,
        &.active {
          background-color: #000;
        }
      }
    }

    &-badges {
      position: absolute;
      top: 12px;
      right: 12px;
      & > span {
        font-size: 13px;
        font-weight: 500;
        line-height: 1;

        position: static;

        display: block;

        margin-bottom: 10px;
        padding: 3px 11px;

        color: #bca487;
        border-radius: 3px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .product-content-2 {
    display: flex;
    justify-content: space-between;

    margin: 20px 0 0;
    .title-price-wrap-2 {
      h3 {
        font-family: $josefin;
        line-height: 1;

        margin: 0 0 6px;
        a {
          font-size: 18px;

          color: #000000;
          &:hover {
            color: #bca487;
          }
        }
      }
      .price-2 {
        span {
          font-size: 15px;

          position: relative;

          margin-right: 17px;

          color: #444;
          &.old {
            text-decoration: line-through;

            color: #444;
          }
          &::before {
            position: absolute;
            top: 10px;
            right: -13px;

            width: 6px;
            height: 2px;

            content: "";

            background-color: #a5a5a5;
          }
          &:last-child:before {
            display: none;
          }
        }
      }
    }
    .pro-wishlist-2 {
      a,
      button {
        font-size: 16px;

        display: inline-block;

        margin: 4px 0 0 10px;

        color: #929292;
        border: none;
        background: none;
        &:hover,
        &.active {
          color: $theme-color;
        }
      }
    }
  }
  &:hover .hover-img {
    visibility: visible;

    transform: translateX(0);

    opacity: 1;
  }
  &:hover .default-img {
    visibility: hidden;
    background-color: white;
    transform: translateX(-100%);
  }
  &:hover .product-action-2 a {
    transform: translateX(0);
  }
  &:hover .product-action-2 button {
    transform: translateX(0);
  }


  &--style2 {
    .product-img {
      .product-action-2 {
        button,
        a {
          color: #000;
          background-color: transparent;
          &:hover,
          &.active {
            color: #bca487;
            background-color: transparent;
          }
        }
      }
    }
    &--blue {
      .product-img {
        .product-action-2 {
          button,
          a {
            color: #000;
            background-color: transparent;
            &:hover,
            &.active {
              color: #053399;
              background-color: transparent;
            }
          }
        }

        &-badges {
          & > span {
            color: #053399;
          }
        }
      }

      .product-content-2 {
        .title-price-wrap-2 {
          h3 {
            a {
              &:hover {
                color: #053399;
              }
            }
          }
        }
      }
    }
  }
}

/* product home 9*/

.collection-wrap-2 {
  margin-top: -175px;
  @media #{$md-layout} {
    margin-top: -97px;
  }
  @media #{$xs-layout} {
    margin-top: -80px;
  }
  @media #{$sm-layout} {
    margin-top: -70px;
  }
}

.collection-product-2 {
  position: relative;

  overflow: hidden;
  a {
    img {
      width: 100%;

      transition: all 0.5s ease 0s;
      transform: scale(1);
    }
  }
  .collection-content-2 {
    position: absolute;
    right: 0;
    bottom: 30px;
    left: 0;

    text-align: center;
    span {
      font-size: 14px;
      font-weight: 500;
    }
    h4 {
      font-size: 16px;
      font-weight: 500;
      line-height: 21px;

      margin: 5px 0 0;
    }
  }
  &:hover a img {
    transform: scale(1.1);
  }
}



/* product hm9 */

.hm9-section-padding {
  .container-fluid {
    padding-right: 70px;
    padding-left: 70px;
    @media #{$xl-layout} {
      padding-right: 15px;
      padding-left: 15px;
    }
    @media #{$lg-layout} {
      padding-right: 30px;
      padding-left: 30px;
    }
    @media #{$md-layout} {
      padding-right: 40px;
      padding-left: 40px;
    }
    @media #{$xs-layout} {
      padding-right: 15px;
      padding-left: 15px;
    }
    @media #{$sm-layout} {
      padding-right: 30px;
      padding-left: 30px;
    }
  }
  .custom-row-4 {
    margin-right: -10px;
    margin-left: -10px;
    @media #{$xs-layout} {
      margin-right: -15px;
      margin-left: -15px;
    }
    @media #{$sm-layout} {
      margin-right: -10px;
      margin-left: -10px;
    }
  }
  div[class^="custom2-"] {
    padding-right: 10px;
    padding-left: 10px;
    @media #{$xs-layout} {
      padding-right: 15px;
      padding-left: 15px;
    }
    @media #{$sm-layout} {
      padding-right: 10px;
      padding-left: 10px;
    }
  }
}
.custom-row-4 {
  display: flex;
  flex-wrap: wrap;
}
.custom2-col-5 {
  position: relative;

  flex: 0 0 20%;

  width: 100%;
  max-width: 20%;
  min-height: 1px;
  @media #{$xl-layout} {
    flex: 0 0 25%;

    max-width: 25%;
  }
  @media #{$lg-layout} {
    flex: 0 0 33.333%;

    max-width: 33.333%;
  }
  @media #{$md-layout} {
    flex: 0 0 50%;

    max-width: 50%;
  }
  @media #{$xs-layout} {
    flex: 0 0 100%;

    max-width: 100%;
  }
  @media #{$sm-layout} {
    flex: 0 0 50%;

    max-width: 50%;
  }
}


/*------- home 14 ----------*/

.product-top-bar {
  display: flex;
  justify-content: space-between;
  @media #{$xs-layout} {
    display: block;
    justify-content: inherit;

    text-align: center;
  }
}

.product-tab-list-3 {
  position: relative;
  z-index: 99;

  margin: 4px 0 0;
  @media #{$xs-layout} {
    justify-content: center;

    margin: 15px 0 0;
  }
  a {
    margin-left: 24px;
    &.nav-link {
      padding: 0;

      background-color: transparent !important;
    }
    &.active {
      h4 {
        color: #242424;
      }
    }
    &:first-child {
      margin-left: 45px;
      @media #{$xs-layout} {
        margin-left: 20px;
      }
    }
    h4 {
      font-size: 14px;
      line-height: 1;

      margin: 0;

      color: #878787;
    }
  }
}

.product-tab-list-5 {
  justify-content: center;

  width: 100%;
  a {
    font-size: 16px;
    line-height: 1;

    display: inline-block;

    margin: 0 20px;
    padding: 0 0 7px;

    color: #000000;
    border-bottom: 2px solid transparent;
    &.nav-link {
      padding: 0;

      border-radius: 0;
      background-color: transparent !important;
    }
    @media #{$xs-layout} {
      font-size: 16px;

      margin: 0 6px;
    }

    &.active {
      border-bottom: 2px solid #59a3e1;
      h4 {
        color: #59a3e1;
      }
    }
  }
  @media #{$xs-layout} {
    &.mb-60 {
      margin-bottom: 25px;
    }
  }
}


.product-tab-list-6 {
  @media #{$xs-layout} {
    margin: 0 0 10px;
  }
  @media #{$sm-layout} {
    margin: 0 0 0;
  }

  .nav-item {
    &:first-child {
      .nav-link {
        padding-left: 0;
      }
    }
  }
  a {
    display: inline-block;

    margin-right: 23px;
    &.nav-link {
      background-color: transparent !important;
    }
    @media #{$xs-layout} {
      margin: 0 23px 10px 0;
    }
    @media #{$sm-layout} {
      margin: 0 23px 0 0;
    }
    &:last-child {
      margin-right: 0;
    }
    h4 {
      font-size: 18px;
      font-weight: 500;

      margin: 0;
      padding-bottom: 5px;

      transition: all 0.3s ease 0s;

      color: #555;
      &:hover {
        color: #000000;
      }
    }
    &.active {
      h4 {
        color: #bca487;
      }
    }
  }
}

.product-tab-list-7 {
  border-bottom: 2px solid #d1d1d1;
  @media #{$xs-layout} {
    margin: 0 0 30px;
  }

  .nav-item {
    &:first-child {
      .nav-link {
        padding-left: 0;
      }
    }
  }
  a {
    display: inline-block;

    margin-right: 23px;
    &.nav-link {
      border-radius: 0;
      background-color: transparent !important;
    }
    @media #{$xs-layout} {
      margin: 0 23px 10px 0;
    }
    @media #{$sm-layout} {
      margin: 0 23px 0 0;
    }
    &:last-child {
      margin-right: 0;
    }
    h4 {
      font-family: $josefin;
      font-size: 24px;
      font-weight: 700;

      margin: 0;
      padding-bottom: 5px;

      transition: all 0.3s ease 0s;

      color: #010101;
      &:hover {
        color: #000000;
      }
    }
    &.active {
      position: relative;
      &:after {
        position: absolute;
        bottom: -2px;
        left: 0;

        width: 100%;
        height: 2px;

        content: "";

        background-color: #053399;
      }

      h4 {
        color: #053399;
      }
    }
  }
}

.product-slider-active {
  .owl-nav {
    position: absolute;
    top: -85px;
    right: 0;
    @media #{$xs-layout} {
      top: -45px;
      right: 0;
      left: 0;

      text-align: center;
    }
    div {
      font-size: 18px;
      line-height: 31px;

      display: inline-block;

      width: 35px;
      height: 35px;

      transition: all 0.3s ease 0s;
      text-align: center;

      color: #c6c6c6;
      border: 1px solid #d2d2d2;
      border-radius: 5px;
      background-color: #f9f9f9;
      @media #{$xs-layout} {
        font-size: 15px;
        line-height: 23px;

        width: 25px;
        height: 25px;
      }
      &:hover {
        color: #fff;
        border: 1px solid #ff3d2a;
        background-color: #ff3d2a;
      }
      &.owl-next {
        margin-left: 3px;
      }
    }
  }
}


.pro-view-more {
  margin-top: 30px;
  @media #{$xs-layout} {
    margin-top: 0;
  }
  a {
    font-size: 16px;
    line-height: 1;

    display: inline-block;

    padding: 20px 35px 19px;

    text-transform: uppercase;

    color: #828282;
    border: 1px solid #d2d2d2;
    i {
      margin-right: 10px;
    }
    &:hover {
      color: #fff;
      border: 1px solid #df262b;
      background-color: #df262b;
    }
  }
}


.bg-png-1 {
  position: absolute;
  top: 0;
  left: 78px;
  @media #{$xs-layout} {
    display: none;
  }
}
.funfact-area {
  position: relative;
}
.bg-png-2 {
  position: absolute;
  top: 0;
  right: 550px;
  @media #{$xx-layout} {
    right: 300px;
  }
  @media #{$xl-layout} {
    right: 250px;
  }
  @media #{$lg-layout} {
    right: 150px;
  }
  @media #{$md-layout} {
    right: 120px;
  }
  @media #{$xs-layout} {
    display: none;
  }
}

.product-quickview-modal-wrapper {
  .modal-dialog {
    margin: 5% auto;
    padding: 0;
  }
}

.category-grid-area {
  margin-top: -100px;

  @media #{$lg-layout} {
    margin-top: -50px;
  }

  @media #{$md-layout} {
    margin-top: 100px;
  }
  @media #{$xs-layout} {
    margin-top: 30px;
  }
}

.category-grid {
  position: relative;
  z-index: 9;
  &__image {
    a {
      img {
        width: 100%;
      }
    }
  }

  &__content {
    position: absolute;
    bottom: 20px;

    width: 100%;
    a {
      font-size: 18px;
      font-weight: 400;

      display: block;

      width: 80%;
      margin: 0 auto;
      padding: 25px 15px;

      text-align: center;

      color: #000;
      background-color: #fff;
      &:hover {
        background-color: #bca487;
      }
    }
  }
}