.lista-nascita-product{
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
  flex-wrap: wrap;
  .product-lista-details{
    margin: 24px;
    .container-prod {
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      margin: auto;
      overflow: hidden;

      max-width: 500px;
      min-height: 550px;
      background: $white;
      box-shadow: 5px 5px 15px rgba($footer_color, .5);
      border-radius: 10px;
      @media #{$xxl-layout} {
        min-width: 500px;
      }
      @media #{$xx-layout} {
        min-width: 500px;
      }
      @media #{$xl-layout} {
        min-width: 500px;
      }
      @media #{$lg-layout} {
        min-width: 500px;
      }

      .container-prod-hor{
        display: flex;
        flex-direction: row;
        justify-content: space-around;

      }
      .accordions{
        font-family: "Poppins", sans-serif;
        .tab {
          width: 100%;
          color: white;
          overflow: hidden;
          .clean{
            position: absolute;
            opacity: 0;
            z-index: -1;
            width: 0px;
            height: 0px;
          }
          &-label {
            font-size: 0.8em;
            display: flex;
            justify-content: space-between;
            padding: .8em;
            background: $midnight;
            font-weight: bold;

            margin-bottom: 0rem !important;

            &:hover {
              background: darken($midnight, 10%);
            }
            &::after {
              content: "\276F";
              width: 1em;
              height: 1em;
              text-align: center;
              transition: all .35s;
            }
          }
          &-content {
            max-height: 0;

            color: $midnight;
            background: white;
            transition: all .35s;
           .personalizzazione-input{
             margin-bottom: 15px;
             width: 100%;

             input{
               height: 100%;
               padding: 5px;
               border-radius: 10px;
               background-color: transparent;
               border: 2px solid #2b7de9;
             }
             input::placeholder {

               font-size: 0.7em;
               font-family: "Poppins", sans-serif;

             }
           }




            .checkbox-wrapper-13 input[type=checkbox] {
              --active: #275EFE;
              --active-inner: #fff;
              --focus: 2px rgba(39, 94, 254, .3);
              --border: #BBC1E1;
              --border-hover: #275EFE;
              --background: #fff;
              --disabled: #F6F8FF;
              --disabled-inner: #E1E6F9;
              -webkit-appearance: none;
              -moz-appearance: none;
              height: 21px;
              outline: none;
              display: inline-block;
              vertical-align: top;
              position: relative;
              margin: 0;
              border: 1px solid var(--bc, var(--border));
              background: var(--b, var(--background));
              transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
            }
            .checkbox-wrapper-13 input[type=checkbox]:after {
              content: "";
              display: block;
              left: 0;
              top: 0;
              position: absolute;
              transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
            }
            .checkbox-wrapper-13 input[type=checkbox]:checked {
              --b: var(--active);
              --bc: var(--active);
              --d-o: .3s;
              --d-t: .6s;
              --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
            }
            .checkbox-wrapper-13 input[type=checkbox]:disabled {
              --b: var(--disabled);
              cursor: not-allowed;
              opacity: 0.9;
            }
            .checkbox-wrapper-13 input[type=checkbox]:disabled:checked {
              --b: var(--disabled-inner);
              --bc: var(--border);
            }
            .checkbox-wrapper-13 input[type=checkbox]:disabled + label {
              cursor: not-allowed;
            }
            .checkbox-wrapper-13 input[type=checkbox]:hover:not(:checked):not(:disabled) {
              --bc: var(--border-hover);
            }
            .checkbox-wrapper-13 input[type=checkbox]:focus {
              box-shadow: 0 0 0 var(--focus);
            }
            .checkbox-wrapper-13 input[type=checkbox]:not(.switch) {
              width: 21px;
            }
            .checkbox-wrapper-13 input[type=checkbox]:not(.switch):after {
              opacity: var(--o, 0);
            }
            .checkbox-wrapper-13 input[type=checkbox]:not(.switch):checked {
              --o: 1;
            }
            .checkbox-wrapper-13 input[type=checkbox] + label {
              display: inline-block;
              vertical-align: middle;
              cursor: pointer;
              margin-left: 8px;
            }

            .checkbox-wrapper-13 input[type=checkbox]:not(.switch) {
              border-radius: 7px;
            }
            .checkbox-wrapper-13 input[type=checkbox]:not(.switch):after {
              width: 5px;
              height: 9px;
              border: 2px solid var(--active-inner);
              border-top: 0;
              border-left: 0;
              left: 7px;
              top: 4px;
              transform: rotate(var(--r, 20deg));
            }
            .checkbox-wrapper-13 input[type=checkbox]:not(.switch):checked {
              --r: 43deg;
            }
            .checkbox-wrapper-13{
              font-size: 0.8em;
              display: flex;
              align-items: center;
              margin-right: 10px;
              margin-bottom: 15px;
            }
            .checkbox-wrapper-13 * {
              box-sizing: inherit;

            }
            .checkbox-wrapper-13 *:before,
            .checkbox-wrapper-13 *:after {
              box-sizing: inherit;
            }
            .border-personalizzazione{
              margin-bottom: 1em;
              width: 100%;
              color: #222 !important;
              font-size: 0.7em;
              display: inline-block;
              border-radius: 4px;
              padding: 8px;
              order: 2;

              border: 2px solid #2b7de9;

              .check-list {
                margin: 0;

              }

              .check-list li {
                position: relative;
                list-style-type: none;
                padding-left: 1.2rem;
                margin-bottom: 0.5rem;
              }

              .check-list li:before {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                top: -2px;
                width: 5px;
                height: 11px;
                border-width: 0 2px 2px 0;
                border-style: solid;
                border-color: #00a8a8;
                transform-origin: bottom left;
                transform: rotate(45deg);
              }
            }
          }
          &-close {
            display: flex;
            justify-content: flex-end;
            padding: 1em;
            font-size: 0.75em;
            background: $midnight;
            cursor: pointer;
            &:hover {
              background: darken($midnight, 10%);
            }
          }
          input:checked {
            +.tab-label {
              background: darken($midnight, 10%);
              &::after {
                transform: rotate(90deg);
              }
            }
            ~.tab-content {
              max-height: 100vh;
              padding: 0.5em;
            }
          }
        }
      }
      .quantity{
        padding-right: 10px;
      }
      .quantity input {
        width: 75px;
        height: 42px;
        line-height: 1.65;
        float: left;
        display: block;
        padding: 0;
        margin: 0;
        padding-left: 20px;
        border: 2px solid $secundary_color;
        background: #ffffff !important;
        border-radius: 10px;
      }

      .quantity input:focus {
        outline: 0;
      }

      .quantity-nav {
        float: left;
        position: relative;
        height: 42px;
      }

      .quantity-button {
        position: relative;
        cursor: pointer;

        width: 20px;
        text-align: center;
        color: #333;
        font-size: 13px;
        font-family: "Trebuchet MS", Helvetica, sans-serif !important;
        line-height: 1.7;
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;
      }

      .quantity-button.quantity-up {
        position: absolute;
        height: 50%;
        top: 0;

        border-bottom: 2px solid #2b7de9;
        border-left: 2px solid #2b7de9;
      }

      .quantity-button.quantity-down {
        position: absolute;
        bottom: 0px;
        height: 55%;

        border-left: 2px solid #2b7de9;
      }
      .first-section-card-prod{
        width: 50%;
        font-family: "Poppins", sans-serif;

        .tag-prodotto-terminato {
          position: absolute;
          top: 0px;
          right: 0px;
          background-color: #ff0000;
          color: #fff;
          padding: 5px 10px;
          border-radius: 10px;
          font-size: 13px;
          font-weight: 600;
        }
      }
      .second-section-card-prod{
        width: 50%;

      }

    }

    p {
      font-size: 0.6em;
      color: $footer_color;
      letter-spacing: 1px;
    }

    h1 {
      font-size: 1.2em;
      color: $dark;
      margin-top: -5px;
    }

    h2 {
      color: $header_color;
      margin-top: -5px;
    }

    img {
      width: 100%;
      margin-top: 47px;
    }

    .slideshow-buttons-prod {
      top: 70%;
      display: none;
    }

    .one-prod, .two-prod, .three-prod, .four-prod {

      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: $secundary_color;
    }

    .one-prod {
      left: 22%;
    }
    .two-prod {
      left: 27%;
    }
    .three-prod {
      left: 32%;
    }
    .four-prod {
      left: 37%;
    }

    .product-prod {
      padding: 25px;

      @media #{$xs-layout} {
        padding: 15px;
      }
      @media #{$sm-layout} {
        padding: 15px;
      }
      @media #{$xxs-layout} {
        padding: 15px;
      }
      @media #{$xxxs-layout} {
        padding: 15px;
      }
      .banner-lista{
        width: 100%;
        margin-bottom: 1rem;
        color: $dark !important;
        font-size: 11px;



        text-transform: uppercase;
        display: inline-block;
        border-radius: 4px;
        padding: 8px;
        order: 2;
        text-align: center;
        font-weight: bold;
        border: 2px solid $secundary_color;
      }
      .qta-lista{
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .buttons-prod{
        display: flex;
        justify-content: space-around;
        button {
          background: darken($secundary_color, 10%);
          padding: 10px;
          display: inline-block;
          outline: 0;
          border: 0;
          margin: -1px;
          border-radius: 2px;
          text-transform: uppercase;
          letter-spacing: 1px;
          color: $white;
          cursor: pointer;
          width: 75px;
          border-radius: 10px;

          &:hover {
            background: $footer_color;
            transition: all .4s ease-in-out;
          }
        }
      }
      .buttons-prod-tag-chicco{
        display: flex;
        justify-content: space-around;
        align-items: center;
        img{
          margin-top: 0px;
        }
      }
      .rwd-table {
        font-size: .8rem;
        font-family: "Poppins", sans-serif;
        margin-bottom: 1rem;
        width: 100%;
        font-weight: 600;

        tr {
          border-top: 1px solid #ddd;
          border-bottom: 1px solid #ddd;
        }

        th {
          display: none; // for accessibility, use a visually hidden method here instead! Thanks, reddit!
        }

        td {
          display: block;

          &:first-child {
            padding-top: .5em;
          }
          &:last-child {
            padding-bottom: .5em;
          }

          &:before {
            content: attr(data-th)": "; // who knew you could do this? The internet, that's who.
            font-weight: bold;

            // optional stuff to make it look nicer
            width: 6.5em; // magic number :( adjust according to your own content
            display: inline-block;
            // end options

            @media (min-width: 200px) {
              display: none;
            }
          }
        }

        th, td {
          text-align: left;

          @media (min-width: 200px) {
            display: table-cell;
            padding: .25em .5em;

            &:first-child {
              padding-left: 0;
            }

            &:last-child {
              padding-right: 0;
            }
          }

        }
      }
      .rwd-table {
        background: $footer_color;
        color: #fff;
        border-radius: .4em;
        overflow: hidden;
        tr {
          border-color: lighten($secundary_color, 10%);
        }
        th, td {
          margin: .5em 1em;
          @media (min-width: 200px) {
            padding: .5em !important;
          }
        }
        th, td:before {
          color: #dd5;
        }
      }
    }

    .desc-prod {
      text-transform: none;
      letter-spacing: 0;

      color: $dark;
      font-size: .7em;
      line-height: 1.6em;

      text-align: justify;

    }

    .sizes-prod {

      color: $secundary_color;


      margin-left: 20px;
      margin-top: 5px;
      &:hover {
        cursor: pointer;
      }
    }
    .colors-prod {

      color: $secundary_color;


      margin-left: 20px;
      margin-top: 5px;
      &:hover {
        cursor: pointer;
      }
    }

    .pick-prod {
      margin-top: 11px;
      margin-bottom:0;
      margin-left: 20px;
    }

    .size-prod {
      width: 100%;
      padding: 9px;
      border: 1px solid $secundary_color;
      font-size: 0.7em;
      text-align: center;
      &:hover{
        background: $footer_color;
        color: $white;
        transition: all .4s ease-in-out;
      }
    }
    .color-prod {
      width: 18%;
      padding: 9px;
      border: 1px solid $secundary_color !important;
      font-size: 0.7em;
      text-align: center;
      &:hover{
        background: $footer_color;
        color: $white;
        transition: all .4s ease-in-out;
      }
      &.blu {border: 1px solid #0000ff;background-color: #0000ff;}
      &.morrone {border: 1px solid #964B00	;background-color: #964B00;}
      &.grigio {border: 1px solid #808080;background-color: #808080;}
      &.verde {border: 1px solid #00FF00;background-color: #00FF00;}
      &.giallo {border: 1px solid #FFFF00;background-color: #FFFF00;}
      &.bianco {border: 1px solid #333;background-color: #fff;}
      &.nero {border: 1px solid #333;background-color: #333;}
      &.rosso {border: 1px solid #FF0000	;background-color: #FF0000	;}
      &.viola {border: 1px solid #800080;background-color: #800080;}
      &.celeste {border: 1px solid #99CBFF;background-color: #99CBFF;}
      &.azzurro {border: 1px solid #007FFF;background-color: #007FFF;}
      &.arancione {border: 1px solid #FF6600;background-color: #FF6600;}
      &.rosa {border: 1px solid #FFC0CB;background-color: #FFC0CB;}
      &.lilla {border: 1px solid #C8A2C8;background-color: #C8A2C8;}
      &.beige {border: 1px solid #F5F5DC;background-color: #F5F5DC;}
      &.bordeaux {border: 1px solid #800000;background-color: #800000;}
      &.turchese {border: 1px solid #30D5C8;background-color: #30D5C8;}
      &.Acquamarina{border: 1px solid #7FFFD4 ;background-color: #7FFFD4;}
      &.Albicocca{border: 1px solid #FBCEB1   ;background-color: #FBCEB1;}
      &.Amaranto{border: 1px solid #E52B50    ;background-color: #E52B50;}
      &.Ambra{border: 1px solid #FFBF00       ;background-color: #FFBF00;}
      &.Ametista{border: 1px solid #884DA7    ;background-color: #884DA7;}
      &.Anguria{border: 1px solid #FC6C85     ;background-color: #FC6C85;}
      &.Antracite{border: 1px solid #293133   ;background-color: #293133;}
      &.Aragosta{border: 1px solid #ED7465    ;background-color: #ED7465;}
      &.Arancione{border: 1px solid #FF6600   ;background-color: #FF6600;}
      &.Ardesia{border: 1px solid #708090     ;background-color: #708090;}
      &.Argento{border: 1px solid #C0C0C0     ;background-color: #C0C0C0;}
      &.Asparago{border: 1px solid #87A96B    ;background-color: #87A96B;}
      &.Avio{border: 1px solid #5D8AA8        ;background-color: #5D8AA8;}
      &.Avorio{border: 1px solid #FFFFF0      ;background-color: #FFFFF0;}
      &.Azalea{border: 1px solid #D3305D      ;background-color: #D3305D;}
      &.Azzurro{border: 1px solid #007FFF     ;background-color: #007FFF;}
      &.Azzurrofiordaliso{border: 1px solid #ABCDEF  ;background-color: #ABCDEF;}
      &.Beige{border: 1px solid #F5F5DC       ;background-color: #F5F5DC;}
      &.Beigeolivachiaro{border: 1px solid #908435  ;background-color: #908435;}
      &.Beigeverdastro{border: 1px solid #BEBD7F     ;background-color: #BEBD7F;}
      &.Bianco{border: 1px solid #FFFFFF      ;background-color: #FFFFFF;}
      &.Biancoantico{border: 1px solid #FFFEEF       ;background-color: #FFFEEF;}
      &.Biancoantiflash{border: 1px solid #F2F3F4   ;background-color: #F2F3F4;}
      &.Biancodititanio{border: 1px solid #FAEBD7   ;background-color: #FAEBD7;}
      &.Biancodizinco{border: 1px solid #FEFEE9     ;background-color: #FEFEE9;}
      &.Biancofantasma{border: 1px solid #F8F8FF     ;background-color: #F8F8FF;}
      &.Biancofloreale{border: 1px solid #FFFAF0     ;background-color: #FFFAF0;}
      &.Biancofumo{border: 1px solid #F5F5F5 ;background-color: #F5F5F5;}
      &.BiancoNavajo{border: 1px solid #FFDEAD       ;background-color: #FFDEAD;}
      &.Biscotto{border: 1px solid #FFE4C4    ;background-color: #FFE4C4;}
      &.Bistro{border: 1px solid #3D2B1F      ;background-color: #3D2B1F;}
      &.Blu{border: 1px solid #0000FF ;background-color: #0000FF;}
      &.Bluacciaio{border: 1px solid #4682B4 ;background-color: #4682B4;}
      &.Blualice{border: 1px solid #F0F8FF   ;background-color: #F0F8FF;}
      &.BluBondi{border: 1px solid #0095B6   ;background-color: #0095B6;}
      &.Blucadetto{border: 1px solid #5F9EA0 ;background-color: #5F9EA0;}
      &.Bluceruleo{border: 1px solid #2A52BE ;background-color: #2A52BE;}
      &.Blucomandostellare{border: 1px solid #007BB8        ;background-color: #007BB8;}
      &.BludiPersia{border: 1px solid #1C39BB       ;background-color: #1C39BB;}
      &.BludiPrussia{border: 1px solid #003153      ;background-color: #003153;}
      &.BluDodger{border: 1px solid #1E90FF  ;background-color: #1E90FF;}
      &.Bluelettrico{border: 1px solid #003399       ;background-color: #003399;}
      &.Blumarino{border: 1px solid #000080  ;background-color: #000080;}
      &.Blumedio{border: 1px solid #0000CD   ;background-color: #0000CD;}
      &.Blunotte{border: 1px solid #191970   ;background-color: #191970;}
      &.Bluoltremare{border: 1px solid #120A8F       ;background-color: #120A8F;}
      &.Blupolvere{border: 1px solid #B0E0E6 ;background-color: #B0E0E6;}
      &.Blupolvere scuro{border: 1px solid #003399   ;background-color: #003399;}
      &.Blureale{border: 1px solid #4169E1   ;background-color: #4169E1;}
      &.Bluscuro{border: 1px solid #00008B   ;background-color: #00008B;}
      &.Bordeaux{border: 1px solid #800000    ;background-color: #800000;}
      &.Borgogna{border: 1px solid #800020    ;background-color: #800020;}
      &.Bronzo{border: 1px solid #CD7F32      ;background-color: #CD7F32;}
      &.Bronzoantico{border: 1px solid #75663F       ;background-color: #75663F;}
      &.Camoscio{border: 1px solid #F0DC82    ;background-color: #F0DC82;}
      &.Carbone{border: 1px solid #050402     ;background-color: #050402;}
      &.Cardo{border: 1px solid #D8BFD8       ;background-color: #D8BFD8;}
      &.Carminio{border: 1px solid #960018    ;background-color: #960018;}
      &.Cartadazucchero{border: 1px solid #E0FFFF   ;background-color: #E0FFFF;}
      &.Castagno{border: 1px solid #CD5C5C    ;background-color: #CD5C5C;}
      &.Castagnoscuro{border: 1px solid #986960      ;background-color: #986960;}
      &.Castagnochiaro{border: 1px solid #DDADAF     ;background-color: #DDADAF;}
      &.Catrame{border: 1px solid #D2B48C     ;background-color: #D2B48C;}
      &.Catramescuro{border: 1px solid #918151       ;background-color: #918151;}
      &.Celadon{border: 1px solid #ACE1AF     ;background-color: #ACE1AF;}
      &.Celeste{border: 1px solid #99CBFF     ;background-color: #99CBFF;}
      &.Ceruleo{border: 1px solid #007BA7     ;background-color: #007BA7;}
      &.Ceruleoscuro{border: 1px solid #08457E       ;background-color: #08457E;}
      &.Chartreuse{border: 1px solid #7FFF00  ;background-color: #7FFF00;}
      &.Ciano{border: 1px solid #00FFFF       ;background-color: #00FFFF;}
      &.Ciliegia{border: 1px solid #DE3163    ;background-color: #DE3163;}
      &.Cioccolato{border: 1px solid #D2691E  ;background-color: #D2691E;}
      &.Cobalto{border: 1px solid #0047AB     ;background-color: #0047AB;}
      &.Conchiglia{border: 1px solid #FFF5EE  ;background-color: #FFF5EE;}
      &.Corallo{border: 1px solid #FF7F50     ;background-color: #FF7F50;}
      &.Crema{border: 1px solid #FFFDD0       ;background-color: #FFFDD0;}
      &.Cremisi{border: 1px solid #DC143C     ;background-color: #DC143C;}
      &.Denim{border: 1px solid #1560BD       ;background-color: #1560BD;}
      &.Denimchiaro{border: 1px solid #5E86C1        ;background-color: #5E86C1;}
      &.Eliotropo{border: 1px solid #DF73FF   ;background-color: #DF73FF;}
      &.Acru{border: 1px solid #C2B280       ;background-color: #C2B280;}
      &.Fioredigranturco{border: 1px solid #6495ED  ;background-color: #6495ED;}
      &.Fogliadite{border: 1px solid #008080       ;background-color: #008080;}
      &.Fucsia{border: 1px solid #F400A1      ;background-color: #F400A1;}
      &.Fulvo{border: 1px solid #EBB55F       ;background-color: #EBB55F;}
      &.Gainsboro{border: 1px solid #DCDCDC   ;background-color: #DCDCDC;}
      &.Giada{border: 1px solid #00A86B       ;background-color: #00A86B;}
      &.Giallo{border: 1px solid #FFFF00      ;background-color: #FFFF00;}
      &.Giallomiele{border: 1px solid #A98307        ;background-color: #A98307;}
      &.GialloNapoli{border: 1px solid #F7E89F       ;background-color: #F7E89F;}
      &.Giallopastello{border: 1px solid #FFFF66     ;background-color: #FFFF66;}
      &.Giallosabbia{border: 1px solid #C6A664       ;background-color: #C6A664;}
      &.Giallosegnale{border: 1px solid #E5BE01      ;background-color: #E5BE01;}
      &.Gialloscuolabus{border: 1px solid #FFD800    ;background-color: #FFD800;}
      &.Glicine{border: 1px solid #C9A0DC     ;background-color: #C9A0DC;}
      &.Granata{border: 1px solid #7B1B02     ;background-color: #7B1B02;}
      &.Grano{border: 1px solid #F5DEB3       ;background-color: #F5DEB3;}
      &.Grigio5{border: 1px solid #F7F7F7   ;background-color: #F7F7F7;}
      &.GrigioAlluminio{border: 1px solid #8C9194   ;background-color: #8C9194;}
      &.Grigioasparago{border: 1px solid #465945     ;background-color: #465945;}
      &.Grigioardesiascuro{border: 1px solid #2F4F4F        ;background-color: #2F4F4F;}
      &.Grigioardesiachiaro{border: 1px solid #778899       ;background-color: #778899;}
      &.Grigiocenere{border: 1px solid #E4E5E0       ;background-color: #E4E5E0;}
      &.Grigiotopo{border: 1px solid #646B63 ;background-color: #646B63;}
      &.Incarnatoprugna{border: 1px solid #CC8899    ;background-color: #CC8899;}
      &.Indaco{border: 1px solid #4B0082      ;background-color: #4B0082;}
      &.Indacoelettrico{border: 1px solid #6F00FF    ;background-color: #6F00FF;}
      &.Indacoscuro{border: 1px solid #310062        ;background-color: #310062;}
      &.InternationalKleinBlue{border: 1px solid #002FA7    ;background-color: #002FA7;}
      &.Isabella{border: 1px solid #F4F0EC    ;background-color: #F4F0EC;}
      &.Kaki{border: 1px solid #C3B091        ;background-color: #C3B091;}
      &.Kakichiaro{border: 1px solid #F0E68C ;background-color: #F0E68C;}
      &.Kakiscuro{border: 1px solid #BDB76B  ;background-color: #BDB76B;}
      &.Lampone{border: 1px solid #E30B5C     ;background-color: #E30B5C;}
      &.Lavanda{border: 1px solid #E6E6FA     ;background-color: #E6E6FA;}
      &.Lavandapallido{border: 1px solid #DABAD0     ;background-color: #DABAD0;}
      &.Lavandarosata{border: 1px solid #FFF0F5      ;background-color: #FFF0F5;}
      &.Limone{border: 1px solid #FDE910      ;background-color: #FDE910;}
      &.Limonecrema{border: 1px solid #FFFACD        ;background-color: #FFFACD;}
      &.Lilla{border: 1px solid #C8A2C8       ;background-color: #C8A2C8;}
      &.Lime{border: 1px solid #CCFF00        ;background-color: #CCFF00;}
      &.Lino{border: 1px solid #FAF0E6        ;background-color: #FAF0E6;}
      &.Magenta{border: 1px solid #FF00FF     ;background-color: #FF00FF;}
      &.Magentachiaro{border: 1px solid #F984E5      ;background-color: #F984E5;}
      &.Magnolia{border: 1px solid #F8F4FF    ;background-color: #F8F4FF;}
      &.Malva{border: 1px solid #E0B0FF       ;background-color: #E0B0FF;}
      &.Malvachiaro{border: 1px solid #996666        ;background-color: #996666;}
      &.Mandarino{border: 1px solid #FFCC00   ;background-color: #FFCC00;}
      &.Marrone{border: 1px solid #964B00     ;background-color: #964B00;}
      &.Marronechiaro{border: 1px solid #CD853F      ;background-color: #CD853F;}
      &.Marronepastello{border: 1px solid #987654    ;background-color: #987654;}
      &.Marronerosso{border: 1px solid #993300       ;background-color: #993300;}
      &.Marronesabbiachiaro{border: 1px solid #DABDAB       ;background-color: #DABDAB;}
      &.Marronescuro{border: 1px solid #654321       ;background-color: #654321;}
      &.Melanzana{border: 1px solid #990066   ;background-color: #990066;}
      &.Mogano{border: 1px solid #C04000      ;background-color: #C04000;}
      &.Nero{border: 1px solid #000000        ;background-color: #000000;}
      &.Ocra{border: 1px solid #CC7722        ;background-color: #CC7722;}
      &.Olivina{border: 1px solid #9AB973     ;background-color: #9AB973;}
      &.Orchidea{border: 1px solid #DA70D6    ;background-color: #DA70D6;}
      &.Oro{border: 1px solid #FFD700 ;background-color: #FFD700;}
      &.OroRosa{border: 1px solid #b76e79 ;background-color: #b76e79;}
      &.Orovecchio{border: 1px solid #CFB53B ;background-color: #CFB53B;}
      &.Ottoneantico{border: 1px solid #CC9966       ;background-color: #CC9966;}
      &.Ottanio{border: 1px solid #00665C     ;background-color: #00665C;}
      &.Papaia{border: 1px solid #FFEFD5      ;background-color: #FFEFD5;}
      &.Pera{border: 1px solid #D1E231        ;background-color: #D1E231;}
      &.Pervinca{border: 1px solid #CCCCFF    ;background-color: #CCCCFF;}
      &.Pesca{border: 1px solid #FFE5B4       ;background-color: #FFE5B4;}
      &.Pesca scuro{border: 1px solid #FFDAB9 ;background-color: #FFDAB9;}
      &.Pescaarancio{border: 1px solid #FFCC99       ;background-color: #FFCC99;}
      &.Pescagiallo{border: 1px solid #FADFAD        ;background-color: #FADFAD;}
      &.Pistacchio{border: 1px solid #93C572  ;background-color: #93C572;}
      &.Platino{border: 1px solid #E5E4E2     ;background-color: #E5E4E2;}
      &.Porpora{border: 1px solid #B20000     ;background-color: #B20000;}
      &.Prugna{border: 1px solid #660066      ;background-color: #660066;}
      &.Rame{border: 1px solid #B87333        ;background-color: #B87333;}
      &.Rosa{border: 1px solid #FFC0CB        ;background-color: #FFC0CB;}
      &.Rosaarancio{border: 1px solid #FF9966        ;background-color: #FF9966;}
      &.Rosamedio{border: 1px solid #DB244F  ;background-color: #DB244F;}
      &.RosaMountbatten{border: 1px solid #997A8D    ;background-color: #997A8D;}
      &.Rosapallido{border: 1px solid #FADADD        ;background-color: #FADADD;}
      &.Rosapastello{border: 1px solid #FFD1DC       ;background-color: #FFD1DC;}
      &.Rosascuro{border: 1px solid #E75480  ;background-color: #E75480;}
      &.Rosashocking{border: 1px solid #FC0FC0       ;background-color: #FC0FC0;}
      &.Rosavivo{border: 1px solid #FF007F   ;background-color: #FF007F;}
      &.Rosso{border: 1px solid #FF0000       ;background-color: #FF0000;}
      &.Rossoaragosta{border: 1px solid #CC5500      ;background-color: #CC5500;}
      &.Rossocardinale{border: 1px solid #C41E3A     ;background-color: #C41E3A;}
      &.Rossocorsa{border: 1px solid #CC0000 ;background-color: #CC0000;}
      &.RossoFalun{border: 1px solid #801818 ;background-color: #801818;}
      &.Rossofragola{border: 1px solid #CE3018       ;background-color: #CE3018;}
      &.Rossofuoco{border: 1px solid #A61022 ;background-color: #A61022;}
      &.Rossomattone{border: 1px solid #B22222       ;background-color: #B22222;}
      &.Rossomattonechiaro{border: 1px solid #BD8E80        ;background-color: #BD8E80;}
      &.Rossopomodoro{border: 1px solid #FF6347      ;background-color: #FF6347;}
      &.Rossopompeiano{border: 1px solid #D21F1B     ;background-color: #D21F1B;}
      &.Rossorosa{border: 1px solid #FF6088  ;background-color: #FF6088;}
      &.Rossosangue{border: 1px solid #500000        ;background-color: #500000;}
      &.Rossosegnale{border: 1px solid #A52019       ;background-color: #A52019;}
      &.RossoTiziano{border: 1px solid #BA6262       ;background-color: #BA6262;}
      &.Rossoveneziano{border: 1px solid #C80815     ;background-color: #C80815;}
      &.Rossoviolettochiaro{border: 1px solid #DB7093       ;background-color: #DB7093;}
      &.Rubino{border: 1px solid #B72755      ;background-color: #B72755;}
      &.Sabbia{border: 1px solid #F4A460      ;background-color: #F4A460;}
      &.Salmone{border: 1px solid #FF8C69     ;background-color: #FF8C69;}
      &.Salmonescuro{border: 1px solid #E9967A       ;background-color: #E9967A;}
      &.Sangria{border: 1px solid #92000A     ;background-color: #92000A;}
      &.Scarlatto{border: 1px solid #FF2400   ;background-color: #FF2400;}
      &.Senape{border: 1px solid #FFDB58      ;background-color: #FFDB58;}
      &.Seppia{border: 1px solid #704214      ;background-color: #704214;}
      &.Solidago{border: 1px solid #DAA520    ;background-color: #DAA520;}
      &.Solidagoscuro{border: 1px solid #B8860B      ;background-color: #B8860B;}
      &.Tanno{border: 1px solid #D2B48C       ;background-color: #D2B48C;}
      &.Tenne{border: 1px solid #CD5700      ;background-color: #CD5700;}
      &.Terradombra{border: 1px solid #635147       ;background-color: #635147;}
      &.Terradombrabruciata{border: 1px solid #8A3324      ;background-color: #8A3324;}
      &.TerradiSiena{border: 1px solid #E97451      ;background-color: #E97451;}
      &.TerradiSienabruciata{border: 1px solid #531B00     ;background-color: #531B00;}
      &.Testadimoro{border: 1px solid #754909       ;background-color: #754909;}
      &.teverde{border: 1px solid #D0F0C0   ;background-color: #D0F0C0;}
      &.Tronco{border: 1px solid #79443B      ;background-color: #79443B;}
      &.Turchese{border: 1px solid #30D5C8    ;background-color: #30D5C8;}
      &.Turchesechiaro{border: 1px solid #08E8DE     ;background-color: #08E8DE;}
      &.Turchesepallido{border: 1px solid #99FFCC    ;background-color: #99FFCC;}
      &.Turchesescuro{border: 1px solid #116062      ;background-color: #116062;}
      &.Uovodipettirosso{border: 1px solid #00CCCC  ;background-color: #00CCCC;}
      &.Uovodipettirossochiaro{border: 1px solid #96DED1   ;background-color: #96DED1;}
      &.Verde{border: 1px solid #00FF00       ;background-color: #00FF00;}
      &.VerdeCaraibi{border: 1px solid #00CC99       ;background-color: #00CC99;}
      &.Verdeforesta{border: 1px solid #228B22       ;background-color: #228B22;}
      &.Verdechiaro{border: 1px solid #66FF00        ;background-color: #66FF00;}
      &.Verdegiallo{border: 1px solid #ADFF2F        ;background-color: #ADFF2F;}
      &.Verdemarino{border: 1px solid #2E8B57        ;background-color: #2E8B57;}
      &.Verdemarinomedio{border: 1px solid #3CB371  ;background-color: #3CB371;}
      &.Verdemarinoscuro{border: 1px solid #8FBC8F  ;background-color: #8FBC8F;}
      &.Verdementa{border: 1px solid #98FF98 ;background-color: #98FF98;}
      &.Verdementachiaro{border: 1px solid #A6FBB2  ;background-color: #A6FBB2;}
      &.Verdemuschio{border: 1px solid #ADDFAD       ;background-color: #ADDFAD;}
      &.Verdeoliva{border: 1px solid #808000 ;background-color: #808000;}
      &.Verdeolivastro{border: 1px solid #6B8E23     ;background-color: #6B8E23;}
      &.Verdeolivascuro{border: 1px solid #556B2F   ;background-color: #556B2F;}
      &.Verdepastello{border: 1px solid #77DD77      ;background-color: #77DD77;}
      &.Verdepino{border: 1px solid #01796F  ;background-color: #01796F;}
      &.Verdeprimavera{border: 1px solid #00FF7F     ;background-color: #00FF7F;}
      &.Verdeprimaverascuro{border: 1px solid #177245       ;background-color: #177245;}
      &.Verdeufficio{border: 1px solid #008000       ;background-color: #008000;}
      &.Verdesmeraldo{border: 1px solid #50C878      ;background-color: #50C878;}
      &.VerdeVeronese{border: 1px solid #40826D      ;background-color: #40826D;}
      &.Vermiglio{border: 1px solid #FF4D00   ;background-color: #FF4D00;}
      &.Viola{border: 1px solid #800080       ;background-color: #800080;}
      &.Violachiaro{border: 1px solid #9F00FF        ;background-color: #9F00FF;}
      &.Violamelanzana{border: 1px solid #991199     ;background-color: #991199;}
      &.Violetto{border: 1px solid #8000FF    ;background-color: #8000FF;}
      &.Vinaccia{border: 1px solid #C0007F    ;background-color: #C0007F;}
      &.Zafferano{border: 1px solid #F4C430   ;background-color: #F4C430;}
      &.Zafferanoprofondo{border: 1px solid #FF9933  ;background-color: #FF9933;}
      &.Zaffiro{border: 1px solid #0F52BA     ;background-color: #0F52BA;}
    }

    .focus-prod{
      background: $footer_color;
      color: $white;
    }
  }
}
.lista-nascita-info{
  margin-bottom: 70px;
  display: flex;
  justify-content: space-evenly;
  background-image: url(https://firebasestorage.googleapis.com/v0/b/website-arredobimbo.appspot.com/o/prova-svg.svg?alt=media&token=4c21d1b8-c1f9-4a12-bdd5-1c2a8ad44223);
  background-blend-mode: color;
  background-position: 50%;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.55);
  width: 100%;
  padding: 50px 30px;
  flex-wrap: wrap;
  .info-lista{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    font-family: "Poppins", sans-serif;
    margin-bottom: 20px;
    font-size: 15px;
    background-color: #333f7b;
    color: white;
    border-radius: 12px;
    padding: 50px;
    border: 2px solid #2b7de9;
  }
  .to-buy-list{
    margin-bottom: 20px;
    font-family: "Poppins", sans-serif;
    ul,
    ol {
      list-style: none;
      color: $white;
    }

    h1 {
      margin-top: 2rem;

      position: relative;
      display: flex;
      align-items: center;
    /*
      &::before,
      &::after {
        content: '';
        display: block;
        width: 5rem;
        height: 2px;
        background-color: currentColor;
        margin: 0 1rem;
      }*/
    }

    ul:not(ul ul) {
      margin-bottom: 0rem;
      //display: grid;
      //grid-template-columns: max-content;
     //z-index: 1;
      //position: relative;
     // perspective: 50vw;
      //transform-style: preserve-3d;

      .done {
        text-decoration: line-through;
      }

      > li {
       // --levitate: 0;
        padding: 1.2rem 2rem;
        background-color: pink;
        position: relative;
        //transform-style: preserve-3d;
        cursor: pointer;
        position: relative;
        transition: transform 0.3s;

        &::after {
          content: '';
          pointer-events: none;
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          opacity: 0;
          transition: opacity 0.3s;
          z-index: 1;
          mix-blend-mode: overlay;
          background-image: linear-gradient(to left, #ffe4b2, #c8b188);
        }

      /*  &:hover {
          --levitate: 1;
          &::before {
            transform: translate3d(6rem, 1rem, -1px) scale(calc(1 - var(--levitate) * 0.1)) rotate(1.5deg);
            filter: blur(8px);
          }

          &::after {
            opacity: 0.6;
          }
        }*/

        &.big {
          background-color: $footer_color;
          padding: 1.6rem 2rem;
          padding-bottom: 3rem;
          border: 2px solid #2b7de9;
          border-radius: 12px;
          font-weight: bolder;
        }

        ul {
          margin-top: 0.5em;
          margin-left: 1em;
          font-size: 0.8em;

          li {
            z-index: 1;
            &:first-letter {
              font-size: 1.4em;
            }
          }
        }
/*
        &:not(:first-of-type) {
          margin-top: 3rem;
        }

        &:nth-child(3n) {
          transform: translate3d(-5px, calc(var(--levitate) * -5px), calc(var(--levitate) * 3rem)) rotate(-0.6deg) rotateX(calc(var(--levitate) * 6deg)) rotateY(calc(var(--levitate) * -3deg));
        }

        &:nth-child(3n-1) {
          transform: translate3d(7px, calc(var(--levitate) * -5px), calc(var(--levitate) * 3rem)) rotate(-0.5deg) rotateX(calc(var(--levitate) * 6deg)) rotateY(calc(var(--levitate) * -3deg));
        }

        &:nth-child(3n-2) {
          transform: translate3d(0px, calc(var(--levitate) * -5px), calc(var(--levitate) * 3rem)) rotate(0.5deg) rotateX(calc(var(--levitate) * 6deg)) rotateY(calc(var(--levitate) * -3deg));
        }*/
/*
        &::before {
          content: '';
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          transition: all 0.3s;
          height: calc(100% - 2px);
          background-color: rgba(0, 0, 0, 0.4);
          z-index: -1;
          transform-origin: bottom left;
          transform: translate3d(8px, 2px, -1px) rotate(1deg);
          filter: blur(2px);
        }*/
      }
    }
  }
}