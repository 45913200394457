.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
    left: 37px;
	background-color:transparent;

	
	text-align:center;
  font-size:30px;
	
  z-index:100;
  
}

.float:hover{ 
	position:fixed; 
	width:60px; 
	height:60px; 
	bottom:40px; 
	right:40px; 
	background-color:transparent; 
	
	
	text-align:center; 
	 font-size:30px; 
	
	 z-index:100; 
   }
.my-float{
  margin-top:16px;
  color:#FFF
}
.my-float-2{
  margin-top:0px;
}
.arrow_box { 
	position: relative; 
	background: #f6f6f6; 
	border: 2px solid #f6f6f6; 
  } 
  .arrow_box:after, .arrow_box:before { 
	left: 45%; 
	border: solid transparent; 
	content: " "; 
	height: 0; 
	width: 0; 
	position: absolute; 
	pointer-events: none; 
  } 
  .arrow_box:before { 
	transform: rotate(270deg); 
	border-left-color: #f6f6f6; 
	border-width: 15px; 
	top: -0.4vh; 
	margin-top: -28px; 
  }
  .swal2-styled.swal2-confirm{
	background-color: #007bff !important;
	box-shadow: none !important
  }
  .swal2-styled{
	background-color: #007bff !important;
	box-shadow: none !important
  }
 
  .swal2-styled.swal2-confirm:hover{
	box-shadow: none !important
  }
  .swal2-styled.swal2-confirm{
	box-shadow: none !important
  }
  
  .tooltip-inner{
	background: #fff none repeat scroll 0 0 !important ;
	background-color: #fff none repeat scroll 0 0 !important ;
	border: 1px solid #ebebeb !important;
	border-radius: 5px !important;
	transition: all .5s ease 0s !important;
	color:black !important;
	opacity: 1 !important;
	z-index: 9999  !important;
	box-shadow: 0 0 7px 0.5px rgb(0 0 0 / 15%) !important ;
  }
  .tooltip.show {
    opacity: 1.9 !important;
}
.bs-tooltip-auto[x-placement^=top] .arrow::before, .bs-tooltip-top .arrow::before {
	border-top-color: white !important;
}
.banner{
		width: 100%;
		background: #64b505;
		color: #fff !important;
		font-size: 11px;
		text-transform: uppercase;
		display: inline-block;
		
		border-radius: 4px;
		padding: 8px;
		order: 2;
		text-align: center;
		font-weight: bold;
		border: 2px solid #3c6808;
		font-size: 13px;
		margin-top: 15px;
	
}
.bannerAfter{

		margin: 0 0 9px;
		padding: 12px 18px 12px 22.5px;
		display: block;
		font-size: 1.3rem;
		background: #e5efe5;
		color: #006400;
		padding-left: 45px;
		position: relative;
	
}
.linea-footer{
	border-color: #666;
    display: block;
    margin: 15px 0;
    width: 100%;
	border: 0;
    border-top: 1px solid #eee;
	margin-left: 30px;
}
.line-footer-text{
	letter-spacing: 0.3px;
    color: #ffffff;
}
.line-footer-text-tel{
	letter-spacing: 0.3px;
    color: #ffffff;
	cursor: pointer;
}
.line-footer-text-tel:hover {
     color: #007bff; 
     text-decoration: underline; 
}
.button-footer-newsletter{
	font-weight: 500;
    line-height: 1;
    z-index: 9;
    display: block;
    width: 100%;
    padding: 18px 20px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #fff;
    border: none;
    border-radius: 50px;
    background: none;
    background-color: #333f7b;
	cursor: pointer;
}
.button-footer-newsletter:hover{
	background-color: #333f7b;
}
.button-footer-newsletter:empty{
	background-color: #333f7b;
}
.input-footer-newsletter{
	background-color: white;
	
    border: 1px solid #ebebeb;
    color: #000;
    height: 40px;
    padding: 0 15px;
}
.input-footer-newsletter:hover{
	background-color: white
}
.input-footer-newsletter:empty{
	background-color: white
}
.border-style-401{

	border-top-left-radius: 37px 140px;
	border-top-right-radius: 23px 130px;
	border-bottom-left-radius: 110px 19px;
	border-bottom-right-radius: 120px 24px;

	display: block;
	position: relative;
	border: solid 3px #333f7b;
	padding: 40px 60px;
	min-width: 1000px;

	width: 70%;
	margin: 10px auto 0;
	font-size: 17px;
	line-height: 28px;
	transform: rotate(-1deg);
	box-shadow: 3px 15px 8px -10px rgba(0, 0, 0, 0.3);
	transition: all 0.13s ease-in;
	display: flex;
	flex-direction: column;
	align-items: center;

}
.border-style-401-tel{

	border-top-left-radius: 37px 140px;
	border-top-right-radius: 23px 130px;
	border-bottom-left-radius: 110px 19px;
	border-bottom-right-radius: 120px 24px;

	display: block;
	position: relative;
	border: solid 3px #333f7b;
	padding: 10px 20px;


	width: 95%;
	margin: 10px auto 0;
	font-size: 17px;
	line-height: 28px;
	transform: rotate(-1deg);
	box-shadow: 3px 15px 8px -10px rgba(0, 0, 0, 0.3);
	transition: all 0.13s ease-in;
	display: flex;
	flex-direction: column;
	align-items: center;

}
.img-401{
	transform: translateY(4px) rotate(-5deg);
	transform: rotate(-1deg);
	transition: all 0.13s ease-in;
}
.border-style-401:hover {
	transform: translateY(-10px) rotate(1deg);
	box-shadow: 3px 15px 8px -10px rgba(0, 0, 0, 0.3);
}

.border-style-401:hover .border {
	transform: translateY(4px) rotate(-5deg);
}
.border {
	position: absolute;
	transition: all 0.13s ease-in;
}

.border:before,
.border:after {
	color: #515d9c;
	font-size: 15px;
	position: absolute;
}
.fa-google {
	background: conic-gradient(from -45deg, #ea4335 110deg, #4285f4 90deg 180deg, #34a853 180deg 270deg, #fbbc05 270deg) 73% 55%/150% 150% no-repeat;
	-webkit-background-clip: text;
	background-clip: text;
	color: transparent;
	-webkit-text-fill-color: transparent;
}
.googleLink{
	background: conic-gradient(from 263deg, #ea4335 93deg, #4285f4 177deg 58deg, #34a853 204deg 270deg, #fbbc05 270deg) 73% 55%/150% 150% no-repeat;
	-webkit-background-clip: text;
	background-clip: text;
	color: transparent;
	-webkit-text-fill-color: transparent;
}
.a401{
	color: #007bff;
	text-decoration: none;
	background-color: transparent;
}
.a401:hover{
	color: #007bff;

	background-color: transparent;
	text-decoration: underline
}