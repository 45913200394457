.breadcrumpmaffle{
  background-color: $header_color;
  color:white;
}
.breadcrumbmaffle-content {
  ul {
    li {
      display: inline-block;
      font-size: 15px;
      font-weight: 500;
      color: white;
      margin-right: 18px;
      text-transform: uppercase;
      position: relative;
      &::before {
        position: absolute;
        width: 18px;
        height: 1px;
        background-color: #5b5858;
        content: "";
        right: -21px;
        top: 12px;
        z-index: 99;
        transform: rotate(115deg);
      }
      &:last-child::before {
        display: none;
      }
      a {
        color: white;
        &:hover {
          color: $theme-color;
        }
      }
    }
  }

  span {
    & > span {
      display: inline-block;
      font-size: 15px;
      font-weight: 500;
      text-transform: uppercase;

      & > a {
        font-weight: 500;
        color: white;
        &:hover {
          color: $theme-color;
        }
      }

      & > span {
        margin-right: 0;
        margin: 0 15px;
      }
    }
  }
}
.information-card {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, $header-color 0px 0px 0px 3px;
  padding: 30px;
  width: 100%;
  margin-top: 3rem;
  border-radius: 20px;
}

.information-card-with-title {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, $header-color 0px 0px 0px 3px;
  padding: 30px;
  width: 100%;
  border-radius: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 90%; /* Larghezza massima del div */
  position: relative;
  margin-bottom: 40px;

}
.information-card-with-title .title {
  position: absolute;
  top: -20px;
  left: 20px;
  background-color: white;
  padding: 5px 10px;
  border-radius: 10px;
  font-weight: bold;
  font-size: 1.3rem;
}

.hidden-table-maffle {
  border-spacing: 10px; /* Spaziatura tra le colonne */
}

.hidden-table-maffle td {
  padding: 5px 10px; /* Spaziatura all'interno delle celle */
  border: 1px solid transparent; /* Nascondi i bordi delle celle */
}

.maffle-button-1 {
  align-self: center;
  background-color: #fff0;
  background-image: none;
  background-position: 0 90%;
  background-repeat: repeat no-repeat;
  background-size: 4px 3px;
  border-radius: 15px 225px 255px 15px 15px 255px 225px 15px;
  border-style: solid;
  border-width: 4px;
  border-color:white;
  box-shadow: rgba(0, 0, 0, .2) 15px 28px 25px -18px;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-family:$play;
  font-size: 1rem;
  line-height: 23px;
  outline: none;
  padding: .75rem;
  text-decoration: none;
  transition: all 235ms ease-in-out;
  border-bottom-left-radius: 15px 255px;
  border-bottom-right-radius: 225px 15px;
  border-top-left-radius: 255px 15px;
  border-top-right-radius: 15px 225px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.maffle-button-1:hover {
  box-shadow: rgba(0, 0, 0, .3) 2px 8px 8px -5px;
  transform: translate3d(0, 2px, 0);
}

.maffle-button-1:focus {
  box-shadow: rgba(0, 0, 0, .3) 2px 8px 4px -6px;
}

.maffle-textarea {
  font-size: 14px;
  width: 100%;
  padding: 10px;
  resize: vertical;
  background: white;
}

.carousel-indicators li {
  width: 40px !important;
  height: 10px !important;

}