.lista-nascita-viewer{
  display: flex;
  flex-direction: column;
  align-items: center;

  .lista-nascita-box{
    padding: 40px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .lista-nascita-box-title{
      font-weight: 500;
      font-size: 30px;
      color: #111;
      margin-bottom: 1.5rem;
      font-family: 'Lobster';
      @media #{$md-layout} {
        font-size: 25px;
      }
      @media #{$xs-layout} {
        font-size: 25px;
      }

      @media #{$sm-layout} {
        font-size: 25px;
      }
      @media #{$xxs-layout} {
        font-size: 25px;
      }
      @media #{$xxxs-layout} {
        font-size: 20px;
      }
    }
    .lista-nascita-box-content{
      text-align: center;
      margin-bottom: 1.5rem;
      h4{
        font-family: $merianda;
        font-weight: 800;
      }
      p {
        font-family: $merianda;
      }
    }
    .box-choice-lista-nascita{
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-around;

      @media #{$xs-layout} {
        flex-direction: column;
      }

      @media #{$sm-layout} {
        flex-direction: column;
      }
      @media #{$xxs-layout} {
        flex-direction: column;
      }
      @media #{$xxxs-layout} {
        flex-direction: column;
      }
      .box-lista-nascita{
        max-height: 230px;
        min-height: 230px;
        background-blend-mode: color;
        background-position: 50%;
        background-repeat: repeat;
        background-size: cover;
        background-color: rgba(0, 0, 0, 0.55);
        margin:6px 6px;
        @media #{$xs-layout} {
          margin: 0px 0px;
          margin-bottom: 6px;
          margin-top: 6px;
        }

        @media #{$sm-layout} {
          margin: 0px 0px;
          margin-bottom: 6px;
          margin-top: 6px;
        }
        @media #{$xxs-layout} {
          margin: 0px 0px;
          margin-bottom: 6px;
          margin-top: 6px;
        }
        @media #{$xxxs-layout} {
          margin: 0px 0px;
          margin-bottom: 6px;
          margin-top: 6px;
        }


        width: 100%;
        border-radius: 30px;
        padding: 50px 30px;

        .box-content{
          display: flex;
          color: white;
          flex-direction: column;
          align-items: center;

          .box-content-title{
            font-family: 'Lobster';
            font-size: 3rem;
            text-align: center;
            margin-bottom: 1.50rem;

            @media #{$lg-layout} {
              font-size: 2rem;
            }
            @media #{$md-layout} {
              font-size: 2rem;
            }
            @media #{$xs-layout} {
              font-size: 2rem;
            }

            @media #{$sm-layout} {
              font-size: 1.5rem;
            }
            @media #{$xxs-layout} {
              font-size: 1.5rem;
            }
            @media #{$xxxs-layout} {
              font-size: 1.5rem;
            }
          }

          .button-55 {
            align-self: center;
            background-color: #fff0;
            background-image: none;
            background-position: 0 90%;
            background-repeat: repeat no-repeat;
            background-size: 4px 3px;
            border-radius: 15px 225px 255px 15px 15px 255px 225px 15px;
            border-style: solid;
            border-width: 4px;
            border-color:white;
            box-shadow: rgba(0, 0, 0, .2) 15px 28px 25px -18px;
            box-sizing: border-box;
            color: white;
            cursor: pointer;
            display: inline-block;
            font-family:$play;
            font-size: 1rem;
            line-height: 23px;
            outline: none;
            padding: .75rem;
            text-decoration: none;
            transition: all 235ms ease-in-out;
            border-bottom-left-radius: 15px 255px;
            border-bottom-right-radius: 225px 15px;
            border-top-left-radius: 255px 15px;
            border-top-right-radius: 15px 225px;
            user-select: none;
            -webkit-user-select: none;
            touch-action: manipulation;
          }

          .button-55:hover {
            box-shadow: rgba(0, 0, 0, .3) 2px 8px 8px -5px;
            transform: translate3d(0, 2px, 0);
          }

          .button-55:focus {
            box-shadow: rgba(0, 0, 0, .3) 2px 8px 4px -6px;
          }


        }
      }
    }
    .lista-nascita-search-box {
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, $header_color 0px 0px 0px 3px;
      padding: 30px;
      width: 100%;
      margin-top: 3rem;
      border-radius: 20px;
      //border-bottom-left-radius: 15px 255px;
      //border-bottom-right-radius: 225px 15px;
      //border-top-left-radius: 255px 15px;
      //border-top-right-radius: 15px 225px;


      .ha-screen-reader{
        width: var(--ha-screen-reader-width, 1px);
        height: var(--ha-screen-reader-height, 1px);
        padding: var(--ha-screen-reader-padding, 0);
        border: var(--ha-screen-reader-border, none);

        position: var(--ha-screen-reader-position, absolute);
        clip: var(--ha-screen-reader-clip, rect(1px, 1px, 1px, 1px));
        overflow: var(--ha-screen-reader-overflow, hidden);
      }

      .field__input{
        --uiFieldPlaceholderColor: var(--fieldPlaceholderColor, #767676);

        background-color: transparent;
        border-radius: 0;
        border: none;

        -webkit-appearance: none;
        -moz-appearance: none;

        font-family: inherit;
        font-size: inherit;
        box-sizing: border-box;
        width: var(--fieldWidth, 100%);
        height: var(--fieldHeight, 3rem);
        padding: var(--fieldPaddingTop, 1.25rem) var(--uiFieldPaddingRight) var(--fieldPaddingBottom, .5rem) var(--uiFieldPaddingLeft);
        border-bottom: var(--uiFieldBorderWidth) solid var(--fieldBorderColor, rgba(0, 0, 0, .25));
      }

      .field__input:focus::-webkit-input-placeholder{
        color: var(--uiFieldPlaceholderColor);
      }

      .field__input:focus::-moz-placeholder{
        color: var(--uiFieldPlaceholderColor);
      }


      .field{
        --uiFieldBorderWidth: var(--fieldBorderWidth, 2px);
        --uiFieldPaddingRight: var(--fieldPaddingRight, 1rem);
        --uiFieldPaddingLeft: var(--fieldPaddingLeft, 1rem);
        --uiFieldBorderColorActive: var(--fieldBorderColorActive, rgba(22, 22, 22, 1));
        width: 100%;
        display: var(--fieldDisplay, inline-flex);
        position: relative;
        font-size: var(--fieldFontSize, 1rem);
        --fieldBorderColor: #333f7b;
        --fieldBorderColorActive: #007bff;
        font-family: "Play", sans-serif;
        font-size: 1.5rem;
        @media #{$lg-layout} {
          font-size: 1rem;
        }
        @media #{$md-layout} {
          font-size: 1rem;
        }
        @media #{$xs-layout} {
          font-size: 1rem;
        }

        @media #{$sm-layout} {
          font-size: 1rem;
        }
        @media #{$xxs-layout} {
          font-size: 1rem;
        }
        @media #{$xxxs-layout} {
          font-size: 1rem;
        }
      }

      .field__input:focus{
        outline: none;
      }

      .field__input::-webkit-input-placeholder{
        opacity: 0;
        transition: opacity .2s ease-out;
      }

      .field__input::-moz-placeholder{
        opacity: 0;
        transition: opacity .2s ease-out;
      }

      .field__input:focus::-webkit-input-placeholder{
        opacity: 1;
        transition-delay: .2s;
      }

      .field__input:focus::-moz-placeholder{
        opacity: 1;
        transition-delay: .2s;
      }

      .field__label-wrap{
        box-sizing: border-box;
        pointer-events: none;
        cursor: text;

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }

      .field__label-wrap::after{
        content: "";
        box-sizing: border-box;
        width: 100%;
        height: 0;
        opacity: 0;

        position: absolute;
        bottom: 0;
        left: 0;
      }

      .field__input:focus ~ .field__label-wrap::after{
        opacity: 1;
      }

      .field__label{
        position: absolute;
        left: var(--uiFieldPaddingLeft);
        top: calc(50% - .5em);

        line-height: 1;
        font-size: var(--fieldHintFontSize, inherit);

        transition: top .2s cubic-bezier(0.9, -0.15, 0.1, 1.15), opacity .2s ease-out, font-size .2s ease-out;
      }

      .field__input:focus ~ .field__label-wrap .field__label,
      .field__input:not(:placeholder-shown) ~ .field__label-wrap .field__label{
        --fieldHintFontSize: var(--fieldHintFontSizeFocused, .75rem);

        top: var(--fieldHintTopHover, .25rem);
      }
      .field_v2 .field__label-wrap{
        overflow: hidden;
      }

      .field_v2 .field__label-wrap::after{
        border-bottom: var(--uiFieldBorderWidth) solid var(--uiFieldBorderColorActive);
        transform: translate3d(-105%, 0, 0);
        transition: transform .285s ease-out .2s, opacity .2s ease-out .2s;
      }

      .field_v2 .field__input:focus ~ .field__label-wrap::after{
        transform: translate3d(0, 0, 0);
        transition-delay: 0;
      }

    }
    .sarch-list-title{
      font-family: $play;
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 0rem;
      margin-top: 2rem;
    }
    .lista-nascita-search-card{
      h4{
        width: 100%;
        font-family: "Play", sans-serif;
      }

      display: flex;
      flex-wrap: wrap;

      width: 100%;
      margin-top: 2rem;
      justify-content: space-around;
      .card-hover {
        border-radius: 30px;
        margin-bottom: 5vh;
        //$root: &;
        width: 300px;
        height: 400px;
        position: relative;
        overflow: hidden;
        box-shadow: 0 0 32px -10px rgba(0,0,0,0.08);

        //background-blend-mode: color;
        //background-position: 50%;
        //background-repeat: repeat;
        //background-size: cover;
        h3{
          margin-bottom: 0.5rem !important;
          font-family: "Play", sans-serif;
          font-weight: 600;
          color:white;
        }
        h4{
          font-size:1rem;
          font-family: "Play", sans-serif;
          font-weight: 600;
          color:white;

          margin-bottom: 0.5rem !important;
        }
        .card-hover__content {
          width: 100%;
          text-align: center;
          background-color: $header_color;
          padding: 0 10px 50px;
          position: absolute;
          bottom: 0;
          left: 0;
          transform: translateY(0);
          transition: all 0.35s 0.35s cubic-bezier(.1,.72,.4,.97);
          will-change: bottom, background-color, transform, padding;
          z-index: 1;


          @media #{$sm-layout} {
            padding: 0 10px 50px;
          }
          @media #{$xxs-layout} {
            padding: 0 10px 50px;
          }
          @media #{$xxxs-layout} {
            padding: 0 10px 50px;
          }
        }
        .card-hover__content::before,
        .card-hover__content::after {
          content: '';
          width: 100%;
          height: 120px;
          background-color: inherit;
          position: absolute;
          left: 0;
          z-index: -1;
        }
        .card-hover__content::before {
          top: -80px;
          clip-path: ellipse(60% 80px at bottom center);
        }

        .card-hover__content::after {
          bottom: -80px;
          clip-path: ellipse(60% 80px at top center);
        }
        .card-hover__title {
          font-size: 1.5rem;
          margin-bottom: 1em;
          font-weight: 600;
          font-family: "Play", sans-serif;
          color:white;
          span {
            color: #2d7f0b;
          }
        }

        .card-hover__text {
          font-size: 0.75rem;
          color:white;
          font-family: "Play", sans-serif;
        }
        .card-hover__link {

          font-family: "Play", sans-serif;
          position: absolute;
          bottom: 1rem;
          left: 50%;
          transform: translate(-50%, 10%);
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          text-decoration: none;
          color: white;
          opacity: 0;
          padding: 10px;
          transition: all 0.35s;



          svg {
            width: 18px;
            margin-left: 4px;
            transition: transform 0.3s;
          }
        }
        .card-hover__link:hover {

          svg {
            transform: translateX(4px);
          }
        }

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          z-index: -1;
          transform: scale(1.2);
          transition: 0.35s 0.35s transform cubic-bezier(.1,.72,.4,.97);
        }
      }
      .card-hover:hover {
        .card-hover__content {
          background-color: $secundary_color;
          bottom: 100%;
          transform: translateY(100%);
          padding: 50px 10px;
          transition: all 0.35s cubic-bezier(.1,.72,.4,.97);
          @media #{$sm-layout} {
            padding: 50px 10px;
          }
          @media #{$xxs-layout} {
            padding: 50px 10px;
          }
          @media #{$xxxs-layout} {
            padding: 50px 10px;
          }
        }

        .card-hover__link {
          opacity: 1;
          transform: translate(-50%, 0);
          transition: all 0.3s 0.35s cubic-bezier(.1,.72,.4,.97);
          color:white;
        }


        img {
          transform: scale(1);
          transition: 0.35s 0.1s transform cubic-bezier(.1,.72,.4,.97);
        }
      }


    }


  }


}
.form-lista-create-section{
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, $quarto-colore 0px 0px 0px 3px;
  padding: 30px;
  width: 100%;
  margin-top: 3rem;
  border-radius: 20px;
  .button-55 {
    align-self: center;
    background-color: #fff0;
    background-image: none;
    background-position: 0 90%;
    background-repeat: repeat no-repeat;
    background-size: 4px 3px;
    border-radius: 15px 225px 255px 15px 15px 255px 225px 15px;
    border-style: solid;
    border-width: 4px;
    border-color:white;
    box-shadow: rgba(0, 0, 0, .2) 15px 28px 25px -18px;
    box-sizing: border-box;
    color: white;
    cursor: pointer;
    display: inline-block;
    font-family:$play;
    font-size: 1rem;
    line-height: 23px;
    outline: none;
    padding: .75rem;
    text-decoration: none;
    transition: all 235ms ease-in-out;
    border-bottom-left-radius: 15px 255px;
    border-bottom-right-radius: 225px 15px;
    border-top-left-radius: 255px 15px;
    border-top-right-radius: 15px 225px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }

  .button-55:hover {
    box-shadow: rgba(0, 0, 0, .3) 2px 8px 8px -5px;
    transform: translate3d(0, 2px, 0);
  }

  .button-55:focus {
    box-shadow: rgba(0, 0, 0, .3) 2px 8px 4px -6px;
  }
  .accordions{
    font-family: "Poppins", sans-serif;
    .tab {
      width: 100%;
      color: white;
      overflow: hidden;
      .clean{
        position: absolute;
        opacity: 0;
        z-index: -1;
        width: 0px;
        height: 0px;
      }
      &-label {
        border-radius: 15px;
        font-size: .95em;
        display: flex;
        justify-content: space-between;
        padding: .8em;
        background: $midnight;
        font-weight: bold;

        margin-bottom: 0rem !important;

        &:hover {
          background: darken($midnight, 10%);
        }
        &::after {
          content: "\276F";
          width: 1em;
          height: 1em;
          text-align: center;
          transition: all .35s;
        }
      }
      &-content {
        max-height: 0;

        color: $midnight;
        background: white;
        transition: all .35s;

        .checkbox-wrapper-13 input[type=checkbox] {
          --active: #275EFE;
          --active-inner: #fff;
          --focus: 2px rgba(39, 94, 254, .3);
          --border: #BBC1E1;
          --border-hover: #275EFE;
          --background: #fff;
          --disabled: #F6F8FF;
          --disabled-inner: #E1E6F9;
          -webkit-appearance: none;
          -moz-appearance: none;
          height: 21px;
          outline: none;
          display: inline-block;
          vertical-align: top;
          position: relative;
          margin: 0;
          border: 1px solid var(--bc, var(--border));
          background: var(--b, var(--background));
          transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
        }
        .checkbox-wrapper-13 input[type=checkbox]:after {
          content: "";
          display: block;
          left: 0;
          top: 0;
          position: absolute;
          transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
        }
        .checkbox-wrapper-13 input[type=checkbox]:checked {
          --b: var(--active);
          --bc: var(--active);
          --d-o: .3s;
          --d-t: .6s;
          --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
        }
        .checkbox-wrapper-13 input[type=checkbox]:disabled {
          --b: var(--disabled);
          cursor: not-allowed;
          opacity: 0.9;
        }
        .checkbox-wrapper-13 input[type=checkbox]:disabled:checked {
          --b: var(--disabled-inner);
          --bc: var(--border);
        }
        .checkbox-wrapper-13 input[type=checkbox]:disabled + label {
          cursor: not-allowed;
        }
        .checkbox-wrapper-13 input[type=checkbox]:hover:not(:checked):not(:disabled) {
          --bc: var(--border-hover);
        }
        .checkbox-wrapper-13 input[type=checkbox]:focus {
          box-shadow: 0 0 0 var(--focus);
        }
        .checkbox-wrapper-13 input[type=checkbox]:not(.switch) {
          width: 21px;
        }
        .checkbox-wrapper-13 input[type=checkbox]:not(.switch):after {
          opacity: var(--o, 0);
        }
        .checkbox-wrapper-13 input[type=checkbox]:not(.switch):checked {
          --o: 1;
        }
        .checkbox-wrapper-13 input[type=checkbox] + label {
          display: inline-block;
          vertical-align: middle;
          cursor: pointer;
          margin-left: 8px;
        }

        .checkbox-wrapper-13 input[type=checkbox]:not(.switch) {
          border-radius: 7px;
        }
        .checkbox-wrapper-13 input[type=checkbox]:not(.switch):after {
          width: 5px;
          height: 9px;
          border: 2px solid var(--active-inner);
          border-top: 0;
          border-left: 0;
          left: 7px;
          top: 4px;
          transform: rotate(var(--r, 20deg));
        }
        .checkbox-wrapper-13 input[type=checkbox]:not(.switch):checked {
          --r: 43deg;
        }
        .checkbox-wrapper-13{
          font-size: 0.8em;
          display: flex;
          align-items: center;
          margin-right: 10px;
          margin-bottom: 15px;
        }
        .checkbox-wrapper-13 * {
          box-sizing: inherit;

        }
        .checkbox-wrapper-13 *:before,
        .checkbox-wrapper-13 *:after {
          box-sizing: inherit;
        }

      }
      &-close {
        display: flex;
        justify-content: flex-end;
        padding: 1em;
        font-size: 0.75em;
        background: $terzo-colore;
        cursor: pointer;
        &:hover {
          background: darken($terzo-colore, 10%);
        }
      }
      input:checked {
        +.tab-label {
          background: $terzo-colore;
          &::after {
            transform: rotate(90deg);
          }
        }
        ~.tab-content {
          max-height: 100vh;
          padding: 0.5em;
        }
      }
    }
  }
  .single-section {
    width: 100%;
    padding: 60px;
    display: inline-block;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 2% 6% 5% 4%/1% 1% 2% 4%;
    text-transform: uppercase;
    background: #ffffff;
    position: relative;
    @media #{$sm-layout} {
      padding: 20px;
    }
    @media #{$xxs-layout} {
      padding: 20px;
    }
    @media #{$xxxs-layout} {
      padding: 20px;
    }
    .img-single-section{
      display: flex;
      flex-wrap: nowrap;
      @media #{$sm-layout} {
        flex-wrap: wrap;

      }
      @media #{$xxs-layout} {
        flex-wrap: wrap;
      }
      @media #{$xxxs-layout} {
        flex-wrap: wrap;
      }
      .img-section{
        width:100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position:center;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, $quarto-colore 0px 0px 0px 3px;
        border-radius: 20px;
        @media #{$sm-layout} {
          height: 100px;

        }
        @media #{$xxs-layout} {
          height: 100px;
        }
        @media #{$xxxs-layout} {
          height: 100px;
        }
      }
      .upload-section{
        width: 100%;
        padding: 30px;
        @media #{$sm-layout} {
          padding: 10px;

        }
        @media #{$xxs-layout} {
          padding: 10px;
        }
        @media #{$xxxs-layout} {
          padding: 10px;
        }
      }

    }
    .image-fallback {
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      background-color: rgba(0, 0, 0, 0.55);
      width: 100%;
      height: 100%;
      border-radius: 20px;
    }

    &::before {
      content: '';
      //border: 2px solid #353535;
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      //transform: translate3d(-50%, -50%, 0) scale(1.015) rotate(0.5deg);
      //border-radius: 1% 1% 2% 4% / 2% 6% 5% 4%;
    }
    .error-lista-nascita{
      color:red;
      @media #{$lg-layout} {
        font-size: 1rem;
      }
      @media #{$md-layout} {
        font-size: 1rem;
      }
      @media #{$xs-layout} {
        font-size: 1rem;
      }

      @media #{$sm-layout} {
        font-size: 1rem;
      }
      @media #{$xxs-layout} {
        font-size: 1rem;
      }
      @media #{$xxxs-layout} {
        font-size: 1rem;
      }
    }
    .ha-screen-reader{
      width: var(--ha-screen-reader-width, 1px);
      height: var(--ha-screen-reader-height, 1px);
      padding: var(--ha-screen-reader-padding, 0);
      border: var(--ha-screen-reader-border, none);

      position: var(--ha-screen-reader-position, absolute);
      clip: var(--ha-screen-reader-clip, rect(1px, 1px, 1px, 1px));
      overflow: var(--ha-screen-reader-overflow, hidden);
    }

    .field__input{

      --uiFieldPlaceholderColor: var(--fieldPlaceholderColor, #767676);

      background-color: transparent;
      border-radius: 0;
      border: none;

      -webkit-appearance: none;
      -moz-appearance: none;

      font-family: inherit;
      font-size: inherit;
      box-sizing: border-box;
      width: var(--fieldWidth, 100%);
      height: var(--fieldHeight, 3rem);
      padding: var(--fieldPaddingTop, 1.25rem) var(--uiFieldPaddingRight) var(--fieldPaddingBottom, .5rem) var(--uiFieldPaddingLeft);
      border-bottom: var(--uiFieldBorderWidth) solid var(--fieldBorderColor, rgba(0, 0, 0, .25));
    }

    .field__input:focus::-webkit-input-placeholder{
      color: var(--uiFieldPlaceholderColor);
    }

    .field__input:focus::-moz-placeholder{
      color: var(--uiFieldPlaceholderColor);
    }




    .field{
      --uiFieldBorderWidth: var(--fieldBorderWidth, 2px);
      --uiFieldPaddingRight: var(--fieldPaddingRight, 1rem);
      --uiFieldPaddingLeft: var(--fieldPaddingLeft, 1rem);
      --uiFieldBorderColorActive: var(--fieldBorderColorActive, rgba(22, 22, 22, 1));
      width: 100%;
      display: var(--fieldDisplay, inline-flex);
      position: relative;
      font-size: var(--fieldFontSize, 1rem);
      --fieldBorderColor: #333f7b;
      --fieldBorderColorActive: #007bff;
      font-family: "Play", sans-serif;
      font-size: 1.5rem;
      @media #{$lg-layout} {
        font-size: 1rem;
      }
      @media #{$md-layout} {
        font-size: 1rem;
      }
      @media #{$xs-layout} {
        font-size: 1rem;
      }

      @media #{$sm-layout} {
        font-size: 1rem;
      }
      @media #{$xxs-layout} {
        font-size: 1rem;
      }
      @media #{$xxxs-layout} {
        font-size: 1rem;
      }
    }

    .field__input:focus{
      outline: none;
    }

    .field__input::-webkit-input-placeholder{
      opacity: 0;
      transition: opacity .2s ease-out;
    }

    .field__input::-moz-placeholder{
      opacity: 0;
      transition: opacity .2s ease-out;
    }

    .field__input:focus::-webkit-input-placeholder{
      opacity: 1;
      transition-delay: .2s;
    }

    .field__input:focus::-moz-placeholder{
      opacity: 1;
      transition-delay: .2s;
    }

    .field__label-wrap{
      box-sizing: border-box;
      pointer-events: none;
      cursor: text;

      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    .field__label-wrap::after{
      content: "";
      box-sizing: border-box;
      width: 100%;
      height: 0;
      opacity: 0;

      position: absolute;
      bottom: 0;
      left: 0;
    }

    .field__input:focus ~ .field__label-wrap::after{
      opacity: 1;
    }

    .field__label{
      position: absolute;
      left: var(--uiFieldPaddingLeft);
      top: calc(50% - .5em);

      line-height: 1;
      font-size: var(--fieldHintFontSize, inherit);

      transition: top .2s cubic-bezier(0.9, -0.15, 0.1, 1.15), opacity .2s ease-out, font-size .2s ease-out;
    }

    .field__input:focus ~ .field__label-wrap .field__label,
    .field__input:not(:placeholder-shown) ~ .field__label-wrap .field__label{
      --fieldHintFontSize: var(--fieldHintFontSizeFocused, .75rem);

      top: var(--fieldHintTopHover, .25rem);
    }
    .field_v2{
      margin-top: 10px;
    }
    .field_v2 .field__label-wrap{
      overflow: hidden;
    }

    .field_v2 .field__label-wrap::after{
      border-bottom: var(--uiFieldBorderWidth) solid var(--uiFieldBorderColorActive);
      transform: translate3d(-105%, 0, 0);
      transition: transform .285s ease-out .2s, opacity .2s ease-out .2s;
    }

    .field_v2 .field__input:focus ~ .field__label-wrap::after{
      transform: translate3d(0, 0, 0);
      //transition-delay: 0;
    }
  }
  .single-section2 {
    --b: .5em; /* border width */
    --c: 3em; /* corner size */
    --r: 2em; /* corner rounding */
    position: relative;
    margin: 1em auto;
    border: solid var(--b) transparent;
    padding: 1em;

    &::before {
      position: absolute;
      z-index: -1;
      inset: calc(-1 * var(--b));
      border: inherit;
      border-radius: var(--r);
      background: linear-gradient(to bottom, $theme-color, $secundary_color) border-box;

      //--corner: conic-gradient(from -90deg at var(--c) var(--c), red 25%, #0000 0) 0 0/ calc(100% - var(--c)) calc(100% - var(--c)) border-box;
      //--inner: conic-gradient(red 0 0) padding-box;
      //-webkit-mask-composite: source-out;
      //mask: var(--corner) subtract, var(--inner);
      content: '';
    }
  }

}
